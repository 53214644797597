import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Paperclip, Send } from "react-feather";
import Helpers from "../../../Config/Helpers";
import PageLoader from "../../../Components/Loader/PageLoader";
import { usePlan } from "../../../Context/PlanContext";

function GenerateReport() {
    const navigate = useNavigate();
    const [userInput, setUserInput] = useState("");
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [titles, setTitles] = useState([]);
    const {isPlanActive, isReadWrite} = usePlan();

    const handleSubmit = async () => {
        setIsLoading(true);
        // setPageLoading(true);
        try {
            const formData = new FormData();
            formData.append("userInput", userInput);

            const response = await axios.post(
                `${Helpers.apiUrl}bot/proposaltitlejson`,
                formData,
                Helpers.authHeaders
            );
            // setUserInput("");
            setTitles(response.data);
        } catch (error) {
            if (error.response) {
                Helpers.toast('error', error.response.data.message);
            } else {
                Helpers.toast('error', 'An error occurred');
            }
            console.error("Error", error);
        }
        setIsLoading(false);
        // setPageLoading(false);
    };

    const regenerateResponse = async () => {
        // console.log('hello',userInput)
        if (userInput.trim() === "") return;
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("userInput", userInput);

            const response = await axios.post(
                `${Helpers.apiUrl}bot/proposaltitlejson`,
                formData,
                Helpers.authHeaders
            );

            setTitles(response.data);

        } catch (error) {
            if (error.response) {
                Helpers.toast('error', error.response.data.message);
            } else {
                Helpers.toast('error', 'An error occurred');
            }
            console.error("Error", error);
        }
        setIsLoading(false);
    };

    const nextTitle = (title) => {
        setIsLoading(true);
        let question = {
            "id": 1,
            "prompt_question_id": 20,
            "answer": title
        };

        // let data = { "questions": [question] };
        let data = { "title": title };

        axios.post(`${Helpers.apiUrl}answer/savetitles`, data, Helpers.authHeaders)
            .then(response => {
                console.log('response', response);
                navigate(`/user/chat/${response.data.chat_id}`);
                setIsLoading(false);
            })
            .catch(error => {
                if (error.response) {
                    Helpers.toast("error", error.response.data.message);
                } else {
                    Helpers.toast("error", "Unexpected error occurred");
                }
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className="nk-content chatbot-mb">
                <div className="container-xl">
                    <div className="nk-content-inner">
                        {pageLoading ? (
                            <PageLoader />
                        ) : (
                            <div className="container my-5">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="card shadow">   
                                            <div className={`card-body ${isPlanActive == "Expired" ? "disabled-overlay" : ""}`}>
                                                <h2 className="card-title mb-4 text-center">Generate Proposal</h2>
                                                <textarea
                                                    className="form-control mb-3"
                                                    id="messageInput"
                                                    rows="4"
                                                    disabled={isReadWrite == 1}
                                                    placeholder={isReadWrite == 1 ? "Read-Only Access Granted" : isPlanActive == "Active" ? "Describe the Proposal or add comments" : isPlanActive == "Expired" ? "Your Plan Is Expired" : ""}
                                                    value={userInput}
                                                    onChange={(e) => setUserInput(e.target.value)}
                                                ></textarea>

                                                <button
                                                    type="button"
                                                    className="btn btn-primary w-100 mt-4"
                                                    id="sendButton"
                                                    onClick={handleSubmit}
                                                    disabled={isLoading || (userInput.trim() === "")}
                                                >
                                                    {isLoading ? "Generating..." : "Generate Proposal Titles"}
                                                </button>

                                                {titles.length > 0 && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary w-100 mt-2"
                                                        onClick={regenerateResponse}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? "Regenerating..." : "Regenerate Titles"}
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        {titles.length > 0 && (
                                            <div className="card shadow mt-4">
                                                <div className="card-body">
                                                    <div className="row">
                                                        {titles.map((title, index) => (
                                                            <div key={index} className="col-12 col-md-4 mb-3 d-flex">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary w-100 fixed-height p-2"
                                                                    onClick={() => nextTitle(title.title)} // Call nextTitle with the selected title
                                                                >
                                                                    {title.title}
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenerateReport;
