import { createClient } from '@supabase/supabase-js';
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai";
import axios from 'axios';
import Helpers from '../Config/Helpers';

const getKeys = async () => {
    try {
        const openAIResponse = await axios.get(
            `${Helpers.apiUrl}user/getOpenAIKey`,
            Helpers.authHeaders
        );
        const supabaseResponse = await axios.get(
            `${Helpers.apiUrl}user/getSupabaseData`,
            Helpers.authHeaders
        );
        
        return {
            openAiApiKey: openAIResponse.data,
            sbUrl: supabaseResponse.data['url'],
            sbApiKey: supabaseResponse.data['api']
        };
    } catch (error) {
        if (error.response) {
            Helpers.toast('error', "Cannot Get API Keys");
        } else {
            Helpers.toast('error', 'Cannot Get API Keys');
        }
        console.error("Error:", error);
        throw new Error("Failed to get API keys");
    }
};

const createRetriever = async () => {
    const { openAiApiKey, sbUrl, sbApiKey } = await getKeys();

    const embeddings = new OpenAIEmbeddings({ openAIApiKey: openAiApiKey });
    const client = createClient(sbUrl, sbApiKey);

    const vectorStore = new SupabaseVectorStore(embeddings, {
        client,
        tableName: 'scrapeddata',
        queryName: 'match_scrapeddata'
    });
    return vectorStore.asRetriever();
};

export { createRetriever };
