import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paperclip, Send } from "react-feather";
import Helpers from "../../../Config/Helpers";
import PageLoader from "../../../Components/Loader/PageLoader";
import { PromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { createRetriever } from "../../../Components/retriever";
import { combineDocs } from "../../../Components/combineDocs";
import { ChatOpenAI } from "@langchain/openai";
import { RunnableSequence, RunnablePassthrough } from "@langchain/core/runnables";

function GenerateInfo() {
    const [openAiApiKey, setOpenAiApiKey] = useState();
    const navigate = useNavigate();
    const [userInput, setUserInput] = useState("");
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState("");

    useEffect(() => {
        getKeys();
    }, []);

    const getKeys = async () => {
        try {
            const response = await axios.get(
                `${Helpers.apiUrl}user/getOpenAIKey`,
                Helpers.authHeaders
            );
            console.log("OpenAI API Key:", response.data);
            setOpenAiApiKey(response.data);
        } catch (error) {
            if (error.response) {
                Helpers.toast('error', "Cannot Get API Keys");
            } else {
                Helpers.toast('error', 'Cannot Get API Keys');
            }
            console.error("Error:", error);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        try {
            const retriever = await createRetriever();
            const llm = new ChatOpenAI({ openAIApiKey: openAiApiKey });
            const standAloneTemplate = `Given a question, convert the question to a standalone question. 
            Question: {question}
            standalone question:`;
            const standAlonePrompt = PromptTemplate.fromTemplate(standAloneTemplate);

            const answerTemplate = `You are a helpful and enthusiastic support bot who can answer a given question based on the context provided. Try to find the answer in the context. If you really don't know the answer, say "I'm sorry, I don't know the answer to that." And direct the questioner to email help@humgpt.com. Don't try to make up an answer. Always speak as if you were chatting to a friend.
            context: {context}
            question: {question}
            answer:`;

            const answerPrompt = PromptTemplate.fromTemplate(answerTemplate);

            const standAloneQuestionChain = standAlonePrompt.pipe(llm).pipe(new StringOutputParser());

            const retrieverChain = RunnableSequence.from([
                async (prevResult) => {
                    console.log("Standalone Question:", prevResult.standalone_question);
                    return { standalone_question: prevResult.standalone_question };
                },
                async ({ standalone_question }) => {
                    const retrievedDocs = await retriever.getRelevantDocuments(standalone_question);
                    console.log("Retrieved Docs:", retrievedDocs);

                    // Ensure all documents are strings
                    return retrievedDocs.map(doc => doc.pageContent);
                },
                async (retrievedTexts) => {
                    console.log("Retrieved Texts:", retrievedTexts);

                    // Check if retrievedTexts contain null values
                    const filteredTexts = retrievedTexts.filter(text => text !== null && text !== undefined);
                    console.log("Filtered Texts:", filteredTexts);

                    const combinedContext = combineDocs(filteredTexts); // Remove await as it's not needed
                    console.log("Combined Context:", combinedContext);
                    return { context: combinedContext };
                }
            ]);

            const answerChain = answerPrompt.pipe(llm).pipe(new StringOutputParser()); // Log the answer prompt

            // Invoke the chain to get the full final response
            const standaloneQuestionResult = await standAloneQuestionChain.invoke({ question: userInput });
            console.log("Standalone Question Result:", standaloneQuestionResult);
            const contextResult = await retrieverChain.invoke({ standalone_question: standaloneQuestionResult });
            console.log("Context Result:", contextResult);

            if (!contextResult.context) {
                throw new Error("Context is undefined");
            }

            const finalPromptData = {
                context: contextResult.context,
                question: userInput,
            };

            console.log("Final Prompt Data:", finalPromptData);

            // Ensure finalPromptData is correctly formatted
            if (!finalPromptData.context || !finalPromptData.question) {
                throw new Error("Missing value for input context or question");
            }

            const finalResponse = await answerChain.invoke(finalPromptData);
            console.log("Final Response:", finalResponse);

            setInfo(finalResponse); // Set the final response as the generated info
            setIsLoading(false);
        } catch (error) {
            if (error.response) {
                Helpers.toast('error', error.response.data.message);
            } else {
                Helpers.toast('error', 'An error occurred');
            }
            console.error("Error:", error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="nk-content chatbot-mb">
                <div className="container-xl">
                    <div className="nk-content-inner">
                        {pageLoading ? (
                            <PageLoader />
                        ) : (
                            <div className="container my-5">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="card shadow">
                                            <div className="card-body">
                                                <h2 className="card-title mb-4 text-center">Generate Information</h2>
                                                <textarea
                                                    className="form-control mb-3"
                                                    id="messageInput"
                                                    rows="4"
                                                    placeholder="Describe the information you need or add comments"
                                                    value={userInput}
                                                    onChange={(e) => setUserInput(e.target.value)}
                                                ></textarea>

                                                <button
                                                    type="button"
                                                    className="btn btn-primary w-100 mt-4"
                                                    id="sendButton"
                                                    onClick={handleSubmit}
                                                    disabled={isLoading || (userInput.trim() === "")}
                                                >
                                                    {isLoading ? "Generating..." : "Generate Information"}
                                                </button>
                                            </div>
                                        </div>
                                        {info && (
                                            <div className="card shadow mt-4">
                                                <div className="card-body">
                                                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                                                        {info}
                                                    </pre>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenerateInfo;
