import axios from "axios";
import React, { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import PageLoader from "../../../Components/Loader/PageLoader";
import { RecursiveCharacterTextSplitter } from 'langchain/text_splitter';
import { createClient } from '@supabase/supabase-js';
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai";

function ScrapeWebsite() {
    const [siteURL, setSiteURL] = useState("");
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState("");
    const [showScrapedData, setShowScrapedData] = useState(false);
    const [sbUrl, setsbUrl] = useState("");
    const [sbApiKey, setSbApiKey] = useState("");
    const [openAiApiKey, setOpenAiApiKey] = useState("");
    const [scrapedWebsites, setScrapedWebsites] = useState([]);

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("siteURL", siteURL);

            const response = await axios.post(
                `${Helpers.apiUrl}admin/scrapewebsite`,
                formData,
                Helpers.authHeaders
            );
            setResult(response.data.data); // Assuming response.data.data is the text result
            console.log("Scraped Data:", response.data.data);

            const splitter = new RecursiveCharacterTextSplitter({
                chunkSize: 500,
                chunkOverlap: 50,
                separators: ['\n\n', '\n', ' ', '']
            });

            const output = await splitter.createDocuments(response.data.data);
            console.log("Split Documents:", output);

            const client = createClient(sbUrl, sbApiKey);
            const embeddings = new OpenAIEmbeddings({ openAIApiKey: openAiApiKey });
            await SupabaseVectorStore.fromDocuments(output, embeddings, {
                client,
                tableName: 'scrapeddata',
            });
            console.log("Data stored in Supabase successfully.");

            await addSiteToScrapedSites(siteURL);
        } catch (error) {
            if (error.response) {
                Helpers.toast('error', error.response.data.message);
            } else {
                Helpers.toast('error', 'An error occurred');
            }
            console.error("Error:", error);
            setIsLoading(false);
            setPageLoading(false);
        }
    };

    const addSiteToScrapedSites = async (siteURL) => {
        try {
            const response = await axios.post(
                `${Helpers.apiUrl}admin/addScrapedSite`,
                { url: siteURL },
                Helpers.authHeaders
            );
            console.log("Site added to scrapedsites:", response.data);

            // Show success toast
            Helpers.toast("success", "Site Scraped Successfully.");
            getSites();
            // Show the scraped data only after the final step is successful
            setShowScrapedData(true);
        } catch (error) {
            if (error.response) {
                Helpers.toast('error', error.response.data.message);
            } else {
                Helpers.toast('error', 'An error occurred');
            }
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
            setPageLoading(false);
        }
    };

    const getKeys = async () => {
        try {
            const response = await axios.get(
                `${Helpers.apiUrl}admin/getApiKey`,
                Helpers.authHeaders
            );
            const response2 = await axios.get(
                `${Helpers.apiUrl}admin/getSupabase`,
                Helpers.authHeaders
            );
            console.log("OpenAI API Key:", response.data);
            console.log("Supabase Data:", response2.data);      

            setOpenAiApiKey(response.data);
            setsbUrl(response2.data['url']);
            setSbApiKey(response2.data['api']);
        } catch (error) {
            if (error.response) {
                Helpers.toast('error', "Cannot Get API Keys");
            } else {
                Helpers.toast('error', 'Cannot Get API Keys');
            }
            console.error("Error:", error);
        }
    };
    const getSites = async () => {
        try {
            setPageLoading(true)
            const response = await axios.get(
                `${Helpers.apiUrl}admin/getScrapedSites`,
                Helpers.authHeaders
            );
            setScrapedWebsites(response.data.data)
            console.log("ScrapedSites:", response.data);
            console.log(scrapedWebsites)
            setPageLoading(false)
        } catch (error) {
            setPageLoading(false)
            if (error.response) {
                Helpers.toast('error', "Cannot Get Scraped Sites");
            } else {
                Helpers.toast('error', 'Cannot Get Scraped Sites');
            }
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        getKeys();
        getSites()
    }, []);

    return (
        <>
            <div className="nk-content chatbot-mb">
                <div className="container-xl">
                    <div className="nk-content-inner">
                        {pageLoading ? (
                            <PageLoader />
                        ) : (
                            <div className="container my-5">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="card shadow">
                                            <div className="card-body">
                                                <h2 className="card-title mb-4 text-center">Scrape Website</h2>
                                                <h4 className="card-title mb-4 text-center">Add Url in Full Form with HTTP:// or HTTPS:// </h4>
                                                <input
                                                    className="form-control mb-3"
                                                    id="messageInput"
                                                    placeholder="Enter website URL"
                                                    value={siteURL}
                                                    onChange={(e) => setSiteURL(e.target.value)}
                                                />

                                                <button
                                                    type="button"
                                                    className="btn btn-primary w-100 mt-4"
                                                    id="sendButton"
                                                    onClick={handleSubmit}
                                                    disabled={isLoading || (siteURL.trim() === "")}
                                                >
                                                    {isLoading ? "Scraping..." : "Scrape Website"}
                                                </button>
                                            </div>
                                        </div>
                                        {showScrapedData && (
                                            <div className="card shadow mt-4">
                                                <div className="card-body">
                                                    <button
                                                        type="button"
                                                        className="btn-close float-end"
                                                        aria-label="Close"
                                                        onClick={() => setShowScrapedData(false)}
                                                    ></button>
                                                    <pre style={{ whiteSpace: 'pre-wrap', maxHeight:'60vh', overflowY:'auto' }}>
                                                        {result}
                                                    </pre>
                                                </div>
                                            </div>
                                        )}
                                        <div className="card shadow mt-4">
                                            <div className="card-body">
                                                <h4 className="card-title mb-4 text-center">Scraped Websites</h4>
                                                <ul className="list-group">
                                                    {scrapedWebsites.length > 0 ? scrapedWebsites.map((url, siteno) => (
                                                        <li key={url} className="list-group-item">
                                                        {siteno+1}. {url} 
                                                        </li>
                                                    )) : "No Websites Scraped Yet"}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ScrapeWebsite;
