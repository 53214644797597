import { useEffect, useRef, useState } from "react";
import PageLoader from "../../../Components/Loader/PageLoader";
import Helpers from "../../../Config/Helpers";
import { useParams } from "react-router-dom";
import axios from "axios";
import APIResponse from "../../../Components/APIResponse";

const SingleChat = () => {
    const { chatid } = useParams();
    const messagesEndRef = useRef(null);
    const [pageLoading, setPageLoading] = useState(false);
    const [chat, setChat] = useState({});
    const [isDone, setIsDone] = useState(true);
    const [messages, setMessages] = useState([]);

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    const getChat = () => {
        if (isDone) {
            setIsDone(false);
            setPageLoading(true);
            axios
                .get(`${Helpers.apiUrl}chat/get/${chatid}`, Helpers.authHeaders)
                .then((response) => {
                    setChat(response.data);
                    setPageLoading(false);
                    setMessages(response.data.messages);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 500);
                });
        }
    };

    useEffect(() => {
        getChat();
    }, []);

    return (
        <>
            <div className="nk-content chatbot-mb">
                <div className="container-xl">
                    <div className="nk-content-inner">
                        {pageLoading ? (
                            <PageLoader />
                        ) : (
                            <div className="nk-content-body">
                                <div className="nk-block">
                                    {messages.map((msg, index) => {
                                        if (msg.is_hidden === 0) {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`chat-bubble-container ${
                                                        msg.is_bot === 1
                                                            ? ""
                                                            : "user-bubble"
                                                    }`}
                                                >
                                                    <div className="chat-bubble">
                                                        <div className="chat-avatar">
                                                            {msg.is_bot === 0 && (
                                                                <div className="media media-sm media-middle media-circle text-bg-primary">
                                                                    <img
                                                                        src={Helpers.serverImage(
                                                                            Helpers.authUser.profile_pic
                                                                        )}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            )}
                                                            {msg.is_bot === 1 && (
                                                                <div className="media media-sm media-middle media-circle text-bg-white">
                                                                    <img
                                                                        src="/favicon.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="chat-message">
                                                            <p className="message">
                                                                <APIResponse response={msg.message} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div id="something" ref={messagesEndRef} />
            </div>
            <style jsx>{`
                .chat-bubble-container {
                    display: flex;
                    margin-bottom: 1rem;
                    align-items: flex-start;
                }

                .user-bubble {
                    justify-content: flex-end;
                }

                .bot-bubble {
                    justify-content: flex-start;
                }

                .chat-bubble {
                    display: flex;
                    align-items: center;
                    background-color: #f1f1f1;
                    border-radius: 15px;
                    padding: 8px 12px;
                    max-width: 80%;
                }

                .user-bubble .chat-bubble {
                    background-color: #e1f5fe;
                }

                .chat-avatar {
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                }

                .user-bubble .chat-avatar {
                    margin-left: 8px;
                    margin-right: 0;
                }

                .chat-message {
                    max-width: 100%;
                }

                .message {
                    margin: 0;
                }
            `}</style>
        </>
    );
};

export default SingleChat;
