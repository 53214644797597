import React, { createContext, useContext, useState, useEffect } from 'react';
import Helpers from '../Config/Helpers';
import axios from 'axios';

const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
    const [isPlanActive, setIsPlanActive] = useState("");
    const [isReadWrite, setIsReadWrite] = useState(null);
    const [userData, setUserData] = useState(null);
    const [tokenFinished, settokenFinished] = useState(false);
    const [userTransactions, setuserTransactions] = useState();
    const [usedTokens, setUsedTokens] = useState();
    const [planTokens, setPlanTokens] = useState();

    const getProfileInfo = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders);
            Helpers.setItem('user', response.data.user, true);
            setUserData(response.data.user);
            setuserTransactions(response.data.transactions);
            console.log("User Data: ", response.data.user);
            const user = response.data.user;
            const totalTokens = response.data.total_used_tokens;
            if(user.org_id != null && user.is_org_owner == 0){
                setPlanTokens((user.organization.no_tokens - totalTokens))
                setUsedTokens((user.used_tokens));
            } else if(user.org_id != null && user.is_org_owner == 1){
                setPlanTokens((user.organization.no_tokens))
                setUsedTokens((totalTokens));
            } else{
                setPlanTokens((user.no_tokens))
                setUsedTokens((user.used_tokens));                
            }
            console.log(totalTokens);
            if ((user.plan_id && user.latestTransaction) || (user.org_id != null && user.latestTransaction)) {
                const expiryDate = new Date(user.latestTransaction.expiry_date);
                const currentDate = new Date();
                if (expiryDate < currentDate) {
                    setIsPlanActive("Expired");
                } else {
                    setIsPlanActive("Active");
                }
            }
            if(user.org_id != null && user.orgPermissions == 1){
                setIsReadWrite(user.orgPermissions);
            } else if(user.org_id != null && user.orgPermissions == 2){
                setIsReadWrite(user.orgPermissions)
            }
            if(user.org_id != null){
                if((user.organization.no_tokens - totalTokens) < 250){
                    settokenFinished(true);
                }
            } else{
                if((user.no_tokens - user.used_tokens) < 250){
                    settokenFinished(true);
                }
            }
        } catch (error) {
            console.error("Error fetching user info: ", error);
        }
    };

    useEffect(() => {
        getProfileInfo();
    }, []);

    return (
        <PlanContext.Provider value={{usedTokens, planTokens , userTransactions ,tokenFinished, getProfileInfo ,isPlanActive, isReadWrite, userData }}>
            {children}
        </PlanContext.Provider>
    );
};

export const usePlan = () => useContext(PlanContext);
