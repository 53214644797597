import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../Hooks/useTitle";
import { useState } from "react";
import axios from "axios";
import Helpers from "../../Config/Helpers";
import GoogleAuthButton from './GoogleAuthButton';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
const Register = () => {
    useTitle("Register");

    const navigate = useNavigate();

    const defaultUser = {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
    }

    const [user, setUser] = useState(defaultUser);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [errors, setErrors] = useState({});
  
    const handleRegistration = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}auth/register`, user, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            localStorage.setItem("user_id", response.data.user_id);
            navigate("/verify-email");
            setIsLoading(false);
        }).catch(error => {
            setErrors(error.response.data.errors || {});
            Helpers.toast("error", error.response.data.message);
            setIsLoading(false);
        });
    }
    const loginWithGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            setIsGoogleLoading(true);
            await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            }).then(response => {
                let data = {
                    first_name: response.data.given_name,
                    last_name: response.data.family_name,
                    email: response.data.email,
                    sub: response.data.sub,
                }
                axios.post(`${Helpers.apiUrl}auth/google-login`, data).then(res => {
                    Helpers.toast("success", res.data.message);
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                    localStorage.setItem('token', res.data.access_token);
                    localStorage.setItem('refresh_token', res.data.refresh_token);
                    let timenow = new Date().getTime();
                    localStorage.setItem('timestamp', timenow);
                    window.location.href = "/user/dashboard";
                    setIsGoogleLoading(false);
                }).catch(err => {
                    Helpers.toast("error", "Unexpected error occured");
                    setIsGoogleLoading(false);
                })
            }).catch(error => {
                console.log(error);
                Helpers.toast("error", "Unexpected error occured");
                setIsGoogleLoading(false);
            });
        },
        onError: error => {
            Helpers.toast("error", "Unexpected error occured Google OAuth");
            setIsLoading(false);
        }
    });
    return (
        <main class="nk-pages">
            <section class="section section-bottom-0 pb-5 has-mask">
                <div class="nk-shape bg-shape-blur-k end-50 top-0"></div>
                <div class="nk-shape bg-shape-blur-l start-50 top-75"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-11 col-xl-10 col-xxl-9">
                                <h6 class="overline-title text-primary">Join HumGPT!</h6>
                                <h2 class="title">Create your free account now</h2>
                            </div>
                        </div>
                    </div>
                    <div class="section-content mb-100">
                        <div class="row g-gs justify-content-center">
                            <div class="col-md-7 col-lg-6 col-xl-5">
                                <div class="card border-0 shadow-sm rounded-4">
                                    <div class="card-body">
                                        <form onSubmit={handleRegistration}>
                                            <div class="row g-1">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-label" for="emailorusername">Name</label>
                                                        <div class="form-control-wrap">
                                                            <input type="text" class="form-control form-control-lg" value={user.name} onChange={e => setUser({ ...user, name: e.target.value })} placeholder="Enter Full Name" />
                                                            <small className="text-danger">{errors.name ? errors.name[0] : ''}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-label" for="emailorusername">Email</label>
                                                        <div class="form-control-wrap">
                                                            <input type="email" class="form-control form-control-lg" value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} placeholder="Enter Email" />
                                                            <small className="text-danger">{errors.email ? errors.email[0] : ''}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-label" for="toggle-password">Password</label>
                                                        <div class="form-control-wrap">
                                                            <input type="password" value={user.password} onChange={e => setUser({ ...user, password: e.target.value })} class="form-control form-control-lg" placeholder="Enter Password" />
                                                            <small className="text-danger">{errors.password ? errors.password[0] : ''}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-label" for="toggle-password">Confirm Password</label>
                                                        <div class="form-control-wrap">
                                                            <input type="password" value={user.password_confirmation} onChange={e => setUser({ ...user, password_confirmation: e.target.value })} class="form-control form-control-lg" placeholder="Confirm Password" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="d-flex flex-wrap justify-content-between has-gap g-3 text-right">
                                                        <a href="/" class="small">Forgot Password?</a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group"><button class="btn btn-primary btn-block" type="submit" disabled={isLoading} onClick={handleRegistration}>{isLoading ? 'Creating account...' : 'Create My Free Account'}</button></div>
                                                </div>
                                            </div>
                                        </form>
                                        <button onClick={loginWithGoogle} disabled={isLoading} className="btn w-100 btn-primary mt-4">
                                            <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px' }}>
                                                <path fill="#ffffff" d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301c1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                                            </svg>
                                            {isGoogleLoading ? 'Please wait...' : 'Sign In with Google'}</button>
                                    </div>
                                </div>
                                <p class="text-center mt-4">Already have an account? <Link to="/login">Login</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Register;