import { useEffect, useState, useRef } from "react";

const TypingEffect = ({ text, isResponseMessage, isLastMessage, speed = 12.5, scrollToBottom }) => {
  const [displayedText, setDisplayedText] = useState(text);
  const intervalRef = useRef(null);
  // const [divisors, setdivisors] = useState([]);
  useEffect(() => {
    if (isLastMessage && isResponseMessage) {
      let currentText = "";
      let index = 0;
      setDisplayedText(""); // Reset the displayed text

      intervalRef.current = setInterval(() => {
        if (index < text.length) {
          console.log(text.length);
          currentText += text.charAt(index);
          setDisplayedText(currentText);
          index++;
          let divisors = [];
          if (text.length >= 600) {
            divisors = [20, 15, 10, 8, 6, 4, 3, 2, 1.5, 1.2]; // 10 divisors for very long text
          } else if (text.length >= 300) {
            divisors = [6, 4, 3, 2, 1.5, 1.2]; // 6 divisors for shorter text
            // divisors = [10, 8, 6, 4, 3, 2, 1.5, 1.2]; // 8 divisors for medium text
          } else if (text.length >= 100) {
            divisors = [4, 2]; // 2 divisors for very short text
          }
          if (divisors.some(divisor => index === Math.round(text.length / divisor))) {
            // console.log("Divisor", index)
            scrollToBottom();
          }
          // else if(index == Math.round(text.length / 6)){
          //   scrollToBottom();
          // }
          // else if(index == Math.round(text.length / 4)){
          //   scrollToBottom();
          // }
          // else if(index == Math.round(text.length / 2)){
          //   scrollToBottom();
          // }
        } 
         else {
          clearInterval(intervalRef.current);
          scrollToBottom();
        }
      }, speed);

      return () => clearInterval(intervalRef.current);
    } else {
      // Set the full text immediately if it's not the last message
      setDisplayedText(text);
    }
  }, [text, isLastMessage, speed]);

  // useEffect(() => {
  //   if (scrollToBottom) {
  //     scrollToBottom();
  //   }
  // }, [displayedText]);

  return <span>{displayedText}</span>;
};

export default TypingEffect;
