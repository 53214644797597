import { useEffect, useRef, useState } from "react";
import PageLoader from "../../../Components/Loader/PageLoader";
import { Clipboard, ThumbsDown, ThumbsUp, Send } from "react-feather";
import Helpers from "../../../Config/Helpers";
import axios from "axios";
import ChatGPTFormatter from "../../../Components/ChatgptFormatter";
import { PromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { createRetriever } from "../../../Components/retriever";
import { combineDocs } from "../../../Components/combineDocs";
import { ChatOpenAI } from "@langchain/openai";
import { RunnableSequence } from "@langchain/core/runnables";
import { HumanMessage } from "@langchain/core/messages";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { usePlan } from "../../../Context/PlanContext";
import TypingEffect from "../../../Components/TypingEffect"; // Import TypingEffect component

const Chatbot = () => {

  const [openAiApiKey, setOpenAiApiKey] = useState();
  const messagesEndRef = useRef(null);
  const { tokenFinished, isPlanActive, isReadWrite, getProfileInfo, userData } = usePlan();
  const [pageLoading, setPageLoading] = useState(false);
  const [chat, setChat] = useState({});
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [chatId, setChatId] = useState(null); // State to store the chat ID
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [prompts, setPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [requestToken, setrequestToken] = useState(null);
  const [responseMessage, setResponseMessage] = useState(false);
  const { chatid } = useParams();
  const [loadingChat, setLoadingChat] = useState(false); // Add a new loading state for chat
  const { state } = location;
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    // If the chatid is missing, reset the chatbot state
    if (!chatid) {
      resetChatbotState();
      
    }
  }, [location]); // Trigger when the route changes

  const resetChatbotState = () => {
    // Set the loader to true immediately so the UI knows it's loading
    setPageLoading(true);

    setMessages([]); // Clear messages
    setChatId(null); // Reset chat ID
    setSelectedTemplate(null); // Reset selected template
    setPrompts([]); // Clear all prompts
    setFilteredPrompts([]); // Clear filtered prompts
    setSelectedCategory(0); // Reset selected category
    setUserInput(""); // Clear user input
    setResponseMessage(false); // Reset response message flag
    setLoadingChat(false); // Stop chat loading
    getCategories(); // Wait for categories to load
    getPrompts(); // Wait for prompts to load
  
    // Fetch categories and prompts asynchronously
    // Using setTimeout to ensure the loader gets time to render before fetching
    
  };
  

  useEffect(() => {
    getKeys();
    getPrompts();
    getCategories();
  }, []);
  useEffect(() => {
    if (chatid) {
      handleFetchChats(chatid);
    }
  }, [chatid]);

  const defaultTemplate = {
    id: 5,
    name: "No prompt",
    description: "No context, just give reply",
  };
  const getKeys = async () => {
    try {
      setPageLoading(true);
      const response = await axios.get(
        `${Helpers.apiUrl}user/getOpenAIKey`,
        Helpers.authHeaders
      );
      console.log("OpenAI API Key:", response.data);
      setOpenAiApiKey(response.data);
      // setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      Helpers.toast("error", "Cannot Get API Keys");
      console.error("Error:", error);
    }
  };
  const getPrompts = () => {
    // setPageLoading(true);
    axios
      .get(`${Helpers.apiUrl}prompt/all-prompts`, Helpers.authHeaders)
      .then((response) => {
        setPrompts(response.data);
        setFilteredPrompts(response.data);

      }).catch((error) => {
        console.log(error);
        setPageLoading(false);
      });
  };

  const getCategories = () => {
    axios
      .get(`${Helpers.apiUrl}category/all`, Helpers.authHeaders)
      .then((response) => {
        setCategories(response.data);
        if(!chatid){
          setPageLoading(false);
        }
      }).catch((error) => {
        console.log(error);
        setPageLoading(false);
      });
  };

  const capitalizeFirstWord = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };



  const filterPrompts = (category_id) => {
    setSelectedCategory(category_id);
    if (category_id === 0) {
      setFilteredPrompts(prompts);
    } else {
      let filtered = prompts.filter((prompt) => prompt.category_id === category_id);
      setFilteredPrompts(filtered);
    }
  };

  const searchTemplates = (e) => {
    let value = e.target.value;
    setSearchQuery(value);
    setSelectedCategory(0);
    let filtered = prompts.filter((prompt) =>
      prompt.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredPrompts(filtered);
  };
  const handleFetchChats = (chatid) => {
    console.log("State is", state)
    if (state !== "noreload" && stateChanged) {
      setLoadingChat(true); // Start showing the loader
      setStateChanged(true); // Set the flag to true after changing the loading state
    } else{
      
    }
    axios
      .get(`${Helpers.apiUrl}chat/get/${chatid}`, Helpers.authHeaders)
      .then((response) => {
        const chatData = response.data;
        console.log(chatData);

        // Set the chat data and ID
        setChat(chatData);
        setChatId(chatData.id);
        // Map messages to the expected format
        setSelectedTemplate(chatData.template != null ? chatData.template : defaultTemplate);
        const formattedMessages = chatData.messages.map((msg) => ({
          message: msg.message,
          is_bot: msg.is_bot === 1,
        }));

        // Clear previous messages and load new ones
        setMessages(formattedMessages);
        setLoadingChat(false); // Stop showing the loader
        setPageLoading(false);

        scrollToBottom(); // Ensure the chat window is scrolled to the bottom
      })
      .catch((error) => {
        console.error("error", error);
        Helpers.toast("error", error.response?.data?.message || "Failed to load chat");
        navigate('/user/dashboard')
        setLoadingChat(false); // Stop showing the loader
      });
  };


  const selectTemplate = (template) => {
    setResponseMessage(true);
    console.log("template", template);
    setSelectedTemplate(template);
    setMessages([{ message: `Prompt "${template.name}" selected. Ask something.`, is_bot: true }]);
    // Helpers.toast("success",`Prompt "${template.name}" selected. Ask something.`)
    setResponseMessage(false);
  };

  const scrollToBottom = () => {
    console.log("Scrolling to bottom.");
    messagesEndRef.current?.scrollIntoView();
  };
  const handleSuggestionClick = async (suggestion, message) => {
    try {
      if (tokenFinished) {
        Helpers.toast("error", "Your tokens are almost finished.");
        return;
      }
      setIsLoading(true);
      const model = new ChatOpenAI({
        openAIApiKey: openAiApiKey, // Include the API key
        model: "gpt-4o-mini",
        temperature: 0
      });
      // Create the prompt based on the suggestion
      const response = await model.invoke([
        new HumanMessage({
          content: `${suggestion} the given data: ${message}. The data will be used as response in a web application chatbot. So just return text and no special characters such as * # etc.`,
        }),
      ]);
      console.log(response);
      const botMessage = response.content;
      setResponseMessage(true);
      setrequestToken(response.response_metadata.tokenUsage.totalTokens);
      console.log("Regenerate Token", requestToken)
      if (requestToken) {
        await axios.post(
          `${Helpers.apiUrl}updateTokens`,
          {
            tokens: requestToken,
          },
          Helpers.authHeaders
        );

        await getProfileInfo();
      } else {
        console.error("Error: requestToken is not set.");
      }
      await axios.post(
        `${Helpers.apiUrl}messages/add`,
        {
          chat_id: chatId,
          message: botMessage,
          is_bot: 1,
          is_hidden: 0,
          is_included: 1,
        },
        Helpers.authHeaders
      );


      // Update messages in the chat UI
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: botMessage, is_bot: true },
      ]);

      // Scroll to the bottom of the chat
      scrollToBottom();
      setIsLoading(false);
      setInterval(() => {
        setResponseMessage(false);
      }, 4000);
    } catch (error) {
      console.error("Error handling suggestion click:", error);
      setIsLoading(false);
      Helpers.toast("error", "Error processing suggestion");
    }
  };


  const handleVectorization = async (question) => {
    try {
      const retriever = await createRetriever();
      const llm = new ChatOpenAI({
        openAIApiKey: openAiApiKey, callbacks: [
          {
            handleLLMEnd(output) {
              setrequestToken(output.llmOutput?.tokenUsage?.totalTokens);
              console.log(`Total Tokens`, requestToken);
            }
            ,
          },
        ],
      });
      const standAloneTemplate = `Given a question, convert the question to a standalone question. 
      Question: {question}
      standalone question:`;
      const standAlonePrompt = PromptTemplate.fromTemplate(standAloneTemplate);

      const answerTemplate = `You are a helpful and enthusiastic support bot who can answer a given question based on the context provided. Try to find the answer in the context. If you really don't know the answer, say "I'm sorry, I don't know the answer to that." And direct the questioner to email help@docsphere.com. Don't try to make up an answer. Always speak as if you were chatting to a friend.
      context: {context}
      question: {question}
      answer:`;

      const answerPrompt = PromptTemplate.fromTemplate(answerTemplate);

      const standAloneQuestionChain = standAlonePrompt.pipe(llm).pipe(new StringOutputParser());

      const retrieverChain = RunnableSequence.from([
        async (prevResult) => {
          return { standalone_question: prevResult.standalone_question };
        },
        async ({ standalone_question }) => {
          const retrievedDocs = await retriever.getRelevantDocuments(standalone_question);
          return retrievedDocs.map(doc => doc.pageContent);
        },
        async (retrievedTexts) => {
          const filteredTexts = retrievedTexts.filter(text => text !== null && text !== undefined);
          const combinedContext = combineDocs(filteredTexts);
          return { context: combinedContext };
        }
      ]);

      const answerChain = answerPrompt.pipe(llm).pipe(new StringOutputParser());

      const standaloneQuestionResult = await standAloneQuestionChain.invoke({ question });
      const contextResult = await retrieverChain.invoke({ standalone_question: standaloneQuestionResult });

      if (!contextResult.context) {
        throw new Error("Context is undefined");
      }

      // console.log("Template Description " ,selectedTemplate.description);  
      const templateDesc = selectedTemplate != null ? selectedTemplate.description : "No context, answer input prompt";
      const finalPromptData = {
        context: `${contextResult.context} ${templateDesc}`,
        question,
      };

      if (!finalPromptData.context || !finalPromptData.question) {
        throw new Error("Missing value for input context or question");
      }

      const finalResponse = await answerChain.invoke(finalPromptData);
      console.log("Final Response : ", finalResponse);
      return finalResponse;
    } catch (error) {
      console.error("Error in vectorization:", error);
      throw error;
    }
  };

  const handleUserInput = async () => {
    if (tokenFinished) {
      Helpers.toast("error", "Your tokens are almost finished.");
      return;
    }
    try {
      setIsLoading(true);
      let currentChatId = chatId;
      let fetchChatId = "";

      if (userInput === "") {
        Helpers.toast("error", "Please Write Something");
        setIsLoading(false);
        return;
      }

      let templateToUse = selectedTemplate;

      // If no template is selected, use the default template
      if (!templateToUse) {
        templateToUse = defaultTemplate;
        setSelectedTemplate(defaultTemplate); // This will asynchronously set it for future use
      }

      // Create a new chat if chatId doesn't exist
      if (!currentChatId) {
        const response = await axios.post(
          `${Helpers.apiUrl}chat/create`,
          { title: userInput, prompt_question_id: templateToUse.id || null },
          Helpers.authHeaders
        );
        const chatData = response.data.chat;
        setChatId(chatData.id);
        setChat(chatData);
        currentChatId = chatData.id;
        fetchChatId = chatData.chatid;
      }

      const userMessage = userInput;
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: userMessage, is_bot: false },
      ]);
      setUserInput("");

      const botMessage = await handleVectorization(userMessage);
      setResponseMessage(true);
      // Save user message
      await axios.post(
        `${Helpers.apiUrl}messages/add`,
        {
          chat_id: currentChatId,
          message: userMessage,
          is_bot: 0,
          is_hidden: 0,
          is_included: 1,
        },
        Helpers.authHeaders
      );

      // Save bot message
      await axios.post(
        `${Helpers.apiUrl}messages/add`,
        {
          chat_id: currentChatId,
          message: botMessage,
          is_bot: 1,
          is_hidden: 0,
          is_included: 1,
        },
        Helpers.authHeaders
      );

      // Update the URL with the chatid after the first response
      if (!chatid) {
        navigate(`/user/generate-info/${fetchChatId}`, { state: "noreload" });
      }

      // Wait for requestToken to be set and update tokens
      if (requestToken) {
        await axios.post(
          `${Helpers.apiUrl}updateTokens`,
          {
            tokens: requestToken,
          },
          Helpers.authHeaders
        );
        await getProfileInfo();
      } else {
        console.error("Error: requestToken is not set.");
      }
      // Update local state and reset input
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: botMessage, is_bot: true },
      ]);
      setInterval(() => {
        setResponseMessage(false);
      }, 4000);
      setIsLoading(false);
      scrollToBottom();
    } catch (error) {
      console.error("Error handling user input:", error);
      setIsLoading(false);
      Helpers.toast("error", "Error handling user input");
    }
  };




  useEffect(() => {
    const updateTokens = async () => {
      if (requestToken) {
        try {
          await axios.post(
            `${Helpers.apiUrl}updateTokens`,
            {
              tokens: requestToken,
            },
            Helpers.authHeaders
          );

          await getProfileInfo();
          console.log("After Fetching", userData.used_tokens);
        } catch (error) {
          console.error("Error updating tokens:", error);
        }
      }
    };

    updateTokens();
  }, [requestToken]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div className="nk-content chatbot-mb">
        <div className="container-xl">
          <div className="nk-content-inner">
            {pageLoading || loadingChat ? (  // Show loader if either page or chat is loading
              <PageLoader />                 // Display the loader here
            ) : (
              <div className={`nk-content-body`}>
                {/* {selectedTemplate */}
                {selectedTemplate && (
                  <h2 className="text-center my-4">Ask Something</h2>
                )}
                
                <div className="nk-block">
                  {!selectedTemplate ? (
                    <>
                      <div className={`nk-block-head  nk-page-head`}>
                        <div className="nk-block-head-between">
                          <div className="nk-block-head-content">
                            <div className="filter-dropdown">
                              <select
                                className="form-select"
                                value={selectedCategory}
                                onChange={(e) => filterPrompts(Number(e.target.value))}
                              >
                                <option value={0}>All</option>
                                {categories.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {capitalizeFirstWord(category.name)}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* <h2 className="display-6">Template Library</h2>
                            <p>Choose the template which you want to use</p> */}
                          </div>
                          <div className="nk-block-head-content">
                            <div className="d-flex gap gx-4">
                              <div className="">
                                <div className="form-control-wrap">
                                  <div className="form-control-icon start md text-light">
                                    <em className="icon ni ni-search"></em>
                                  </div>
                                  <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={searchTemplates}
                                    className="form-control form-control-md"
                                    placeholder="Search Prompt"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-block">

                        <div className={`row g-gs ${isReadWrite == 1 ? "disabled-overlay" : isPlanActive == "Expired" ? "disabled-overlay" : ""} filter-container`} data-animation="true">
                          {filteredPrompts.map((prompt) => {
                            return (
                              <div key={prompt.id} className="col-sm-4 col-xxl-3 filter-item blog-content">
                                <button
                                  onClick={() => selectTemplate(prompt)}
                                  className="card card-full shadow-none"
                                >
                                  <div className="card-body">
                                    <div className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3">
                                      {prompt.name.charAt(0)}
                                    </div>
                                    <h5 className="fs-4 fw-medium">{capitalizeFirstWord(prompt.name)}</h5>
                                    <p className="small text-light line-clamp-2">{capitalizeFirstWord(prompt.description)}</p>
                                  </div>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {messages.map((msg, index) => {
                        const isLastMessage = index === messages.length - 1;
                        const lastIndex = messages.length - 1;

                        return (
                          <div
                            key={index}
                            className={`container chat-box ${msg.is_bot ? "bot-bubble" : "bg-white"}`}
                          >
                            <div className="row">
                              <div className="col-12">
                                <div className="row align-center">
                                  <div className="col-6">
                                    <div className="chat-header">
                                      {msg.is_bot ? (
                                        <div className="media media-middle media-circle text-bg-primary">
                                          <img src="/favicon.png" alt="" />
                                        </div>
                                      ) : (
                                        <img
                                          className="chat-avatar"
                                          src={Helpers.serverImage(Helpers.authUser.profile_pic)}
                                          alt=""
                                        />
                                      )}
                                      <span className="chat-user">
                                        <strong>{msg.is_bot ? "HumGPT" : "You"}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-6 text-right">
                                    <div className="chat-actions">
                                      <Clipboard
                                        className="pointer"
                                        size={20}
                                        onClick={() => {
                                          if (msg.is_bot) {
                                            navigator.clipboard.writeText(msg.message)
                                              .then(() => {
                                                Helpers.toast("success", "Copied To Clipboard");
                                              })
                                              .catch(err => {
                                                console.error('Failed to copy text: ', err);
                                              });
                                          }
                                        }}
                                      />

                                      {/* <ThumbsUp className="pointer ml20" size={20} />
                <ThumbsDown className="pointer ml20" size={20} /> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="chat-divider"></div>
                              </div>
                              <div className="col-12">
                                <p className="message">
                                  {msg.is_bot ? (
                                    <TypingEffect text={msg.message} isResponseMessage={responseMessage} isLastMessage={isLastMessage}
                                     scrollToBottom={scrollToBottom}
                                    />
                                  ) : (
                                    <ChatGPTFormatter
                                      response={msg.message}
                                      writing={isLastMessage && isLoading}
                                    />
                                  )}
                                </p>
                              </div>
                            </div>
                            {console.log(isLastMessage)}
                            {msg.is_bot && index != 0 && index == lastIndex && (
                              <div className="suggestions my-1">
                                <button
                                  disabled={isLoading || (isReadWrite != null && isReadWrite == 1)}
                                  onClick={() => handleSuggestionClick("expand the content to a proper understandable answer", msg.message)}
                                  className="btn btn-primary px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition"
                                >
                                  Expand Response
                                </button>
                                <button
                                  disabled={isLoading || (isReadWrite != null && isReadWrite == 1)}
                                  onClick={() => handleSuggestionClick("summarize the given point in a very concise manner and make it short", msg.message)}
                                  className="btn btn-secondary px-4 py-2 rounded-lg shadow-md hover:bg-gray-700 transition"
                                >
                                  Summarize
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      })}


                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div id="something" ref={messagesEndRef} />
      </div>
      <div style={{ marginBottom: "0rem" }} className={`chat-bottom ${isPlanActive == "Expired" ? "disabled-overlay" : isPlanActive == "Expired" ? "disabled-overlay" : ""}`}>
        <div className="container-xl">
          <div className="row">
            <div className="col-12 p0">
              <div className="form-group">
                <div className="form-control-wrap">
                  <textarea
                    className="chatbot-input"
                    value={userInput}
                    disabled={isLoading || (isReadWrite != null && isReadWrite == 1)}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder={isReadWrite == 1 ? "Read-Only Access Granted" : isPlanActive == "Active" ? "Write your message..." : isPlanActive == "Expired" ? "Your Plan Is Expired" : "Write Something"}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-12 p0">
              <div className="row">
                <div className="col-12 text-right">
                  <small>{userInput.length} / 2000</small>
                  <button
                    className="btn btn-primary btn-sm ml20"
                    disabled={isLoading || (isReadWrite != null && isReadWrite == 1)}
                    onClick={() => handleUserInput()}
                  >
                    {isLoading ? "Sending..." : <><Send size={14} /> <span className="ml10">Send</span></>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
                .chat-bubble-container {
                    display: flex;
                    margin-bottom: 1rem;
                    align-items: flex-start;
                }

                .user-bubble {
                    justify-content: flex-end;
                }

                .bot-bubble {
                    justify-content: flex-start;
                }

                .chat-bubble {
                    display: flex;
                    align-items: center;
                    background-color: #f1f1f1;
                    border-radius: 15px;
                    padding: 8px 12px;
                    max-width: 80%;
                }
                .card {
                  transition: box-shadow 0.3s ease, transform 0.3s ease;
-webkit-box-shadow: 2px 2px 8px 1px rgba(31, 141, 244, 0.44);
-moz-box-shadow: 2px 2px 8px 1px rgba(31, 141, 244, 0.44);
box-shadow: 2px 2px 8px 1px rgba(31, 141, 244, 0.44) !important;
                }

                .card:hover {
-webkit-box-shadow: 2px 2px 8px 2px rgba(31, 141, 244, 0.44);
  -moz-box-shadow: 2px 2px 8px 2px rgba(31, 141, 244, 0.44);
  box-shadow: 2px 2px 8px 2px rgba(31, 141, 244, 0.44) !important;
                  transform: translateY(-10px);
                }
                .user-bubble .chat-bubble {
                    background-color: #e1f5fe;
                }

                .chat-avatar {
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                }

                .user-bubble .chat-avatar {
                    margin-left: 8px;
                    margin-right: 0;
                }

                .chat-message {
                    max-width: 100%;
                }

                .message {
                    margin: 0;
                }
                .nk-footer{
                  display:none;
                }
                .suggestions {
  display: flex;
  gap: 10px;
  padding: 0px 40px;
  margin-top: 1rem;
}

.suggestions .btn {
  padding: 0.2rem 0.6rem !important;
  border-radius: 0.4rem;
  font-size: 0.700rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestions .btn-primary {
  border: 1px solid #21A1F6;
  background-color: #21A1F6;
  color: white;
}

.suggestions .btn-primary:hover {
  background-color: #001120;
  border: 1px solid #001120;
}

.suggestions .btn-secondary {
  background-color: #21A1F6;
  border: 1px solid #21A1F6;
  color: white;
}

.suggestions .btn-secondary:hover {
  background-color: #001120;
  border: 1px solid #001120;
}

            `}</style>
    </>
  );
};

export default Chatbot;
