import { useEffect, useRef, useState } from "react";
import PageLoader from "../../../Components/Loader/PageLoader";
import { Clipboard, ThumbsDown, ThumbsUp, Send } from "react-feather";
import Helpers from "../../../Config/Helpers";
import axios from "axios";
import ChatGPTFormatter from "../../../Components/ChatgptFormatter";
import { PromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { createRetriever } from "../../../Components/retriever";
import { combineDocs } from "../../../Components/combineDocs";
import { ChatOpenAI } from "@langchain/openai";
import { RunnableSequence } from "@langchain/core/runnables";
import { HumanMessage  } from "@langchain/core/messages";
import { Link, useLocation } from "react-router-dom";
import { usePlan } from "../../../Context/PlanContext";
import TypingEffect from "../../../Components/TypingEffect"; // Import TypingEffect component

const Chat = () => {
	
  const [openAiApiKey, setOpenAiApiKey] = useState();
  const messagesEndRef = useRef(null);
  const {tokenFinished, isPlanActive, isReadWrite, getProfileInfo, userData} = usePlan();
  const [pageLoading, setPageLoading] = useState(false);
  const [chat, setChat] = useState({});
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(null); // State to store the chat ID
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [prompts, setPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const {state} = useLocation()

  const [requestToken, setrequestToken] = useState(null);

  // useEffect(() => {
  //   getKeys();
  //   getPrompts();
  //   getCategories();
  // }, []);
  useEffect(() => {
    console.log("State Data", state)
  }, []);

  // const defaultTemplate = {
  //   name: "No prompt",
  //   description: "No context, just give reply",
  // };
  // const getKeys = async () => {
  //   try {
  //     setPageLoading(true);
  //     const response = await axios.get(
  //       `${Helpers.apiUrl}user/getOpenAIKey`,
  //       Helpers.authHeaders
  //     );
  //     console.log("OpenAI API Key:", response.data);
  //     setOpenAiApiKey(response.data);
  //     setPageLoading(false);
  //   } catch (error) {
  //     setPageLoading(false);
  //     Helpers.toast("error", "Cannot Get API Keys");
  //     console.error("Error:", error);
  //   }
  // };

  // const getCategories = () => {
  //   axios
  //     .get(`${Helpers.apiUrl}category/all`, Helpers.authHeaders)
  //     .then((response) => {
  //       setCategories(response.data);
  //     });
  // };

  // const capitalizeFirstWord = (str) => {
  //   if (!str) return str;
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // };

  // const getPrompts = () => {
  //   setPageLoading(true);
  //   axios
  //     .get(`${Helpers.apiUrl}prompt/all-prompts`, Helpers.authHeaders)
  //     .then((response) => {
  //       setPrompts(response.data);
  //       setFilteredPrompts(response.data);
  //       setPageLoading(false);
  //     });
  // };

  // const filterPrompts = (category_id) => {
  //   setSelectedCategory(category_id);
  //   if (category_id === 0) {
  //     setFilteredPrompts(prompts);
  //   } else {
  //     let filtered = prompts.filter((prompt) => prompt.category_id === category_id);
  //     setFilteredPrompts(filtered);
  //   }
  // };

  // const searchTemplates = (e) => {
  //   let value = e.target.value;
  //   setSearchQuery(value);
  //   setSelectedCategory(0);
  //   let filtered = prompts.filter((prompt) =>
  //     prompt.name.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setFilteredPrompts(filtered);
  // };

  // const selectTemplate = (template) => {
  //   setSelectedTemplate(template);
  //   setMessages([{ message: `Prompt "${template.name}" selected. Ask something.`, is_bot: true }]);
  // };

  // const scrollToBottom = () => {
  //   console.log("Scrolling to bottom.");
  //   messagesEndRef.current?.scrollIntoView();
  //   // if (messagesEndRef.current)
  //   //   messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  // };
  // const handleSuggestionClick = async (suggestion, message) => {
  //   try {
  //     if (tokenFinished) {
  //       Helpers.toast("error", "Your tokens are almost finished.");
  //       return;
  //     }
  //     setIsLoading(true);
  //     const model = new ChatOpenAI({
  //       openAIApiKey: openAiApiKey, // Include the API key
  //       model: "gpt-4o-mini",
  //       temperature: 0
  //     });
  //     // Create the prompt based on the suggestion
  //     const response = await model.invoke([
  //       new HumanMessage({
  //         content: `${suggestion} the given data: ${message}. The data will be used as response in a web application chatbot. So just return text and no special characters such as * # etc.`,
  //       }),
  //     ]);
  //     console.log(response);
  //     const botMessage = response.content;
  //     setrequestToken(response.response_metadata.tokenUsage.totalTokens);
  //     console.log("Regenerate Token", requestToken)
  //     if (requestToken) {
  //       await axios.post(
  //         `${Helpers.apiUrl}updateTokens`,
  //         {
  //           tokens: requestToken,
  //         },
  //         Helpers.authHeaders
  //       );
  
  //       await getProfileInfo();
  //     } else {
  //       console.error("Error: requestToken is not set.");
  //     }
  //     await axios.post(
  //       `${Helpers.apiUrl}messages/add`,
  //       {
  //         chat_id: chatId,
  //         message: botMessage,
  //         is_bot: 1,
  //         is_hidden: 0,
  //         is_included: 1,
  //       },
  //       Helpers.authHeaders
  //     );
  
  //     // Update messages in the chat UI
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       { message: botMessage, is_bot: true },
  //     ]);
  
  //     // Scroll to the bottom of the chat
  //     scrollToBottom();
  //     setIsLoading(false);
  
  //   } catch (error) {
  //     console.error("Error handling suggestion click:", error);
  //     setIsLoading(false);
  //     Helpers.toast("error", "Error processing suggestion");
  //   }
  // };
  
  
  // const handleVectorization = async (question) => {
  //   try {
  //     const retriever = await createRetriever();
  //     const llm = new ChatOpenAI({ openAIApiKey: openAiApiKey ,   callbacks: [
  //       {
  //         handleLLMEnd(output) {
  //           setrequestToken(output.llmOutput?.tokenUsage?.totalTokens);
  //           console.log(`Total Tokens`, requestToken);
  //         }
  //         ,
  //       },
  //     ],});
  //     const standAloneTemplate = `Given a question, convert the question to a standalone question. 
  //     Question: {question}
  //     standalone question:`;
  //     const standAlonePrompt = PromptTemplate.fromTemplate(standAloneTemplate);

  //     const answerTemplate = `You are a helpful and enthusiastic support bot who can answer a given question based on the context provided. Try to find the answer in the context. If you really don't know the answer, say "I'm sorry, I don't know the answer to that." And direct the questioner to email help@humgpt.com. Don't try to make up an answer. Always speak as if you were chatting to a friend.
  //     context: {context}
  //     question: {question}
  //     answer:`;

  //     const answerPrompt = PromptTemplate.fromTemplate(answerTemplate);

  //     const standAloneQuestionChain = standAlonePrompt.pipe(llm).pipe(new StringOutputParser());

  //     const retrieverChain = RunnableSequence.from([
  //       async (prevResult) => {
  //         return { standalone_question: prevResult.standalone_question };
  //       },
  //       async ({ standalone_question }) => {
  //         const retrievedDocs = await retriever.getRelevantDocuments(standalone_question);
  //         return retrievedDocs.map(doc => doc.pageContent);
  //       },
  //       async (retrievedTexts) => {
  //         const filteredTexts = retrievedTexts.filter(text => text !== null && text !== undefined);
  //         const combinedContext = combineDocs(filteredTexts);
  //         return { context: combinedContext };
  //       }
  //     ]);

  //     const answerChain = answerPrompt.pipe(llm).pipe(new StringOutputParser());

  //     const standaloneQuestionResult = await standAloneQuestionChain.invoke({ question });
  //     const contextResult = await retrieverChain.invoke({ standalone_question: standaloneQuestionResult });

  //     if (!contextResult.context) {
  //       throw new Error("Context is undefined");
  //     }

  //     // console.log("Template Description " ,selectedTemplate.description);  
  //     const templateDesc = selectedTemplate != null ? selectedTemplate.description : "No context, answer input prompt";
  //     const finalPromptData = {
  //       context: `${contextResult.context} ${templateDesc}`,
  //       question,
  //     };

  //     if (!finalPromptData.context || !finalPromptData.question) {
  //       throw new Error("Missing value for input context or question");
  //     }

  //     const finalResponse = await answerChain.invoke(finalPromptData);
  //     console.log("Final Response : ", finalResponse);
  //     return finalResponse;
  //   } catch (error) {
  //     console.error("Error in vectorization:", error);
  //     throw error;
  //   }
  // };

  // const handleUserInput = async () => {
  //   if (tokenFinished) {
  //     Helpers.toast("error", "Your tokens are almost finished.");
  //     return;
  //   }
  //   try {
  //     setIsLoading(true);
  
  //     let currentChatId = chatId;
  
  //     if (userInput === "") {
  //       Helpers.toast("error", "Please Write Something");
  //       setIsLoading(false);
  //       return;
  //     }
  
  //     // If no template is selected, set the default template
  //     if (!selectedTemplate) {
  //       setSelectedTemplate(defaultTemplate);
  //     }
  
  //     // Create a new chat if chatId doesn't exist
  //     if (!currentChatId) {
  //       const response = await axios.post(
  //         `${Helpers.apiUrl}chat/create`,
  //         { title: userInput },
  //         Helpers.authHeaders
  //       );
  //       const chatData = response.data.chat;
  //       setChatId(chatData.id);
  //       setChat(chatData);
  //       currentChatId = chatData.id;
  //     }
  
  //     const userMessage = userInput;
  //     const botMessage = await handleVectorization(userMessage);
  
  //     // Save user message
  //     await axios.post(
  //       `${Helpers.apiUrl}messages/add`,
  //       {
  //         chat_id: currentChatId,
  //         message: userMessage,
  //         is_bot: 0,
  //         is_hidden: 0,
  //         is_included: 1,
  //       },
  //       Helpers.authHeaders
  //     );
  
  //     // Save bot message
  //     await axios.post(
  //       `${Helpers.apiUrl}messages/add`,
  //       {
  //         chat_id: currentChatId,
  //         message: botMessage,
  //         is_bot: 1,
  //         is_hidden: 0,
  //         is_included: 1,
  //       },
  //       Helpers.authHeaders
  //     );
  
  //     // Wait for requestToken to be set and update tokens
  //     if (requestToken) {
  //       await axios.post(
  //         `${Helpers.apiUrl}updateTokens`,
  //         {
  //           tokens: requestToken,
  //         },
  //         Helpers.authHeaders
  //       );
  
  //       await getProfileInfo();
  //     } else {
  //       console.error("Error: requestToken is not set.");
  //     }
  
  //     // Update local state and reset input
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       { message: userMessage, is_bot: false },
  //       { message: botMessage, is_bot: true },
  //     ]);
  //     // setSelectedTemplate(null); // Hide the prompt after the first message
  //     setUserInput("");
  //     scrollToBottom();
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error handling user input:", error);
  //     setIsLoading(false);
  //     Helpers.toast("error", "Error handling user input");
  //   }
  // };
  
  
  // useEffect(() => {
  //   const updateTokens = async () => {
  //     if (requestToken) {
  //       try {
  //         await axios.post(
  //           `${Helpers.apiUrl}updateTokens`,
  //           {
  //             tokens: requestToken,
  //           },
  //           Helpers.authHeaders
  //         );
  
  //         await getProfileInfo();
  //         console.log("After Fetching", userData.used_tokens);
  //       } catch (error) {
  //         console.error("Error updating tokens:", error);
  //       }
  //     }
  //   };
  
  //   updateTokens();
  // }, [requestToken]);
  
  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  return (
    <div>

    </div>
  );
};

export default Chat;
