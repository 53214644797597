import { useEffect, useState, useRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import moment from "moment";
import Moment from "react-moment";
import { X } from "react-feather";
import logo from './../../../humgpt-white-logo.png';
import PageLoader from "../../Components/Loader/PageLoader";
import { usePlan } from "../../Context/PlanContext";

const UserLayout = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDataReady, setIsDataReady] = useState(false);
  const {isPlanActive, userData} = usePlan();

  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isCompact, setIsCompact] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [authUser, setAuthUser] = useState(Helpers.authUser);
  const [isLoading, setIsLoading] = useState(true);
  const sidebarContentRef = useRef(null); 

  const logout = (e) => {
    e.preventDefault();
    Helpers.toast("success", "Logged out successfully");
    localStorage.clear();
    navigate("/");
  };

  const getUserIdFromLocalStorage = () => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const userId = storedUser && storedUser.id;
    return userId;
  };
  useEffect(() => {
    if (isPlanActive !== undefined && userData !== undefined) {
      setIsDataReady(true);
    }
  }, [isPlanActive, userData]);
  
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.permissions) {
      setPermissions(storedUser.permissions.split(','));
    }
    Helpers.toggleCSS();
    setShowMobileNav(false);

    getUserIdFromLocalStorage();
    if(isPlanActive == "Expired"){
      Helpers.toast("error",'Your Plan Is Expired');
  }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  const toggleSidebar = () => {
    setIsCompact(!isCompact);
    if (sidebarContentRef.current) {
      sidebarContentRef.current.scrollLeft = isCompact ? 0 : sidebarContentRef.current.scrollWidth;
    }
  };

  if (!userData) {
    return <PageLoader />; // or any other loading component you prefer
  }
  return (
    <div class="nk-app-root" data-sidebar-collapse="lg">
      <div class="nk-main">
        {isLoading && <PageLoader />}
        {!isLoading && (
          <>
            {showMobileNav && (
              <div
                onClick={() => setShowMobileNav(false)}
                className="sidebar-overlay"
              ></div>
            )}
            <div
              class={`nk-sidebar nk-sidebar-fixed ${isCompact && "is-compact"} ${showMobileNav && "sidebar-active"}`}
              id="sidebar"
            >
              <div class="nk-compact-toggle">
                <button
                  onClick={toggleSidebar}
                  class="btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3"
                >
                  <em
                    class={`icon off ni ${isCompact ? "ni-chevron-right" : "ni-chevron-left"}`}
                  ></em>
                </button>
              </div>
              <div class="nk-sidebar-element nk-sidebar-head">
                <div class="nk-sidebar-brand">
                  <a href="/" class="logo-link">
                    <div class="logo-wrap">
                      <img
                        class="logo-img logo-light"
                        src={logo}
                        alt=""
                        style={{width:'120px',height:'120px',marginTop:'-40px',marginLeft:'-10px'}}
                      />
                      <img
                        class="logo-img logo-dark"
                        src={logo}
                        alt=""
                        style={{width:'120px',height:'120px',marginTop:'-40px',marginLeft:'-10px'}}
                      />
                      <img
                        class="logo-img logo-icon compact-logo"
                        src={logo}
                        alt=""
                        style={{width:'120px',height:'120px',marginTop:'-40px',marginLeft:'-10px'}}
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div class="nk-sidebar-element nk-sidebar-body">
                <div class="nk-sidebar-content h-100" id="sidebar-content" data-simplebar ref={sidebarContentRef}>
                  <div class="nk-sidebar-menu" style={{ overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none', scrollBehavior: 'smooth' }}>
                    <ul class="nk-menu">
                      <li class="nk-menu-item">
                        <Link to="/user/dashboard" class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-dashboard"></em>
                          </span>
                          <span class="nk-menu-text">Dashboard</span>
                        </Link>
                      </li>
                      {/* <li class="nk-menu-item">
                        <Link to="/user/templates-library" class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-layers"></em>
                          </span>
                          <span class="nk-menu-text">Templates Library</span>
                        </Link>
                      </li> */}
                      {/* {authUser.is_subscription_active === 1 && (
                        <li class="nk-menu-item">
                          <Link to="/user/my-templates-library" class="nk-menu-link">
                            <span class="nk-menu-icon">
                              <em class="icon ni ni-layers"></em>
                            </span>
                            <span class="nk-menu-text">My Templates Library</span>
                          </Link>
                        </li>
                      )} */}
                      <li class="nk-menu-item">
                        <Link to="/user/generate-info" class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-mail"></em>
                          </span>
                          <span class="nk-menu-text">Prompt Library</span>
                        </Link>
                      </li>
                      {userData.is_org_owner == 1 && (
                      <li class="nk-menu-item">
                        <Link to="/user/organization-members" class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-mail"></em>
                          </span>
                          <span class="nk-menu-text">Organization</span>
                        </Link>
                      </li>
                      )}
                      {/* <li class="nk-menu-item">
                        <Link to="/user/generate-email" class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-mail"></em>
                          </span>
                          <span class="nk-menu-text">Generate Email</span>
                        </Link>
                      </li>
                      <li className="nk-menu-item">
                        <Link to="/user/generate-report"
                          class="nk-menu-link"
                        >
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-file"></em>
                          </span>
                          <span className="nk-menu-text">Create Report</span>
                        </Link>
                      </li>
                      <li class="nk-menu-item">
                        <Link to="/user/generate-plans" class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-text-rich"></em>
                          </span>
                          <span class="nk-menu-text">Generate Plans</span>
                        </Link>
                      </li>
                      <li className="nk-menu-item">
                        <Link
                          to={"/user/generate-proposal"}
                          className="nk-menu-link"
                        >
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-layers"></em>
                          </span>
                          <span className="nk-menu-text">Create Proposal</span>
                        </Link>
                      </li> */}
                      <li class="nk-menu-item">
                        <Link to="/user/chat-history" class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-clock"></em>
                          </span>
                          <span class="nk-menu-text">History</span>
                        </Link>
                      </li>
                      {/* <li class="nk-menu-item">
                        <Link to={"/user/categories"} class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-tag"></em>
                          </span>
                          <span class="nk-menu-text">Categories</span>
                        </Link>
                      </li>
                      <li class="nk-menu-item">
                        <Link to={"/user/Prompt"} class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-text-rich"></em>
                          </span>
                          <span class="nk-menu-text">Documents</span>
                        </Link>
                      </li>
                      <li class="nk-menu-item">
                        <Link to={"/user/prompts"} class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-file"></em>
                          </span>
                          <span class="nk-menu-text">Prompts</span>
                        </Link>
                      </li>
                      <li class="nk-menu-item">
                        <Link to={"/user/instructions"} class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-info"></em>
                          </span>
                          <span class="nk-menu-text">Instructions</span>
                        </Link>
                      </li>
                      <li class="nk-menu-item">
                        <Link to={"/user/buttons"} class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-view-grid"></em>
                          </span>
                          <span class="nk-menu-text">Automation Buttons</span>
                        </Link>
                      </li> */}
                  {(userData.is_org_owner == 1 || (userData.is_org_owner == 0 && userData.org_id == null)) && (
                      <li class="nk-menu-item">
                        <Link to={"/user/pricing-plans"} class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-sign-usdc"></em>
                          </span>
                          <span class="nk-menu-text">Pricing Plans</span>
                        </Link>
                      </li>
                  )}
                      <li class="nk-menu-item">
                        <a href="#!" onClick={logout} class="nk-menu-link">
                          <span class="nk-menu-icon">
                            <em class="icon ni ni-signout"></em>
                          </span>
                          <span class="nk-menu-text">Sign Out</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="nk-sidebar-element nk-sidebar-footer">
                <div class="nk-sidebar-footer-extended pt-3">
                  <div class="border border-primary rounded-3">
                    
                    <div class="px-3 py-2 border-light rounded-top-3">
                    {userData.plan && (
                      <div class="d-flex flex-wrap align-items-center justify-content-between">
                        <h6 class="lead-text color-primary">
                          {userData.plan.plan_name}
                        </h6>
                        <Link class="link color-white" to={"/user/pricing-plans"}>
                          <em class="ni ni-spark-fill icon text-warning"></em>
                          <span>{ isPlanActive == "Active" ? "Upgrade" : isPlanActive == "Expired" ? "Renew" : "" }</span>
                        </Link>
                      </div>)}
                      {isPlanActive == "Active" &&(
                      <h6 className="lead-text mt-2">
                        <span className="color-white">Expire On: </span>
                        <Moment
                          className="color-primary"
                          date={userData.latestTransaction.expiry_date}
                          format="MMM Do YYYY"
                        />
                      </h6>
                      )}
                      {isPlanActive == "Expired" &&(
                        <h6 className="lead-text mt-2">
                        <span className="text-danger">Your Plan Is Expired</span>
                      </h6>
                      )}
                    </div>
                    <Link
                      class="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3"
                      to={`/user/profile/${getUserIdFromLocalStorage()}`}
                    >
                      <div class="media-group">
                        <div class="media media-sm media-middle media-circle text-bg-primary">
                          <img
                            className="chat-avatar"
                            src={Helpers.serverImage(Helpers.authUser.profile_pic)}
                            alt=""
                          />
                        </div>
                        <div class="media-text">
                          <h6 class="fs-6 mb-0 color-white">
                            {Helpers.authUser.name}
                          </h6>
                        </div>
                        <em class="icon ni ni-chevron-right ms-auto ps-1 color-white"></em>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="nk-wrap">
              <div class="nk-header nk-header-fixed">
                <div class="container-fluid">
                  <div class="nk-header-wrap">
                    <div class="nk-header-logo ms-n1">
                      <div class="nk-sidebar-toggle me-1">
                        <button class="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none">
                          <em class="icon ni ni-menu"> </em>
                        </button>
                        <button
                          onClick={() => setShowMobileNav(true)}
                          class="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"
                        >
                          <em class="icon ni ni-menu"> </em>
                        </button>
                      </div>
                      <a href="index-2.html" class="logo-link">
                        <div class="logo-wrap">
                          <img
                            class="logo-img logo-light"
                            src="/logo-dashboard.png"
                            srcset="/logo-dashboard.png 2x"
                            alt=""
                          />
                          <img
                            class="logo-img logo-dark"
                            src="/logo-dashboard.png"
                            srcset="/logo-dashboard.png 2x"
                            alt=""
                          />
                          <img
                            class="logo-img logo-icon"
                            src="/logo-dashboard.png"
                            srcset="/logo-dashboard.png 2x"
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                    <div class="nk-header-tools">
                      <ul class="nk-quick-nav ms-2">
                        <li class="dropdown d-inline-flex">
                          <Link to={`/user/profile/${getUserIdFromLocalStorage()}`} class="nk-menu-link">
                            <div class="media media-sm media-middle media-circle text-bg-primary">
                              <img
                                className="chat-avatar"
                                src={Helpers.serverImage(
                                  Helpers.authUser.profile_pic
                                )}
                                alt=""
                              />
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <Outlet />
              {!location.pathname.includes("/chat") && (
                <div class="nk-footer">
                  <div class="container-xl">
                    <div class="d-flex align-items-center flex-wrap justify-content-between mx-n3">
                      <div class="nk-footer-links px-3">
                        <ul class="nav nav-sm">
                          <li class="nav-item">
                            <a class="nav-link" href="#!">
                              Home
                            </a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#!">
                              Pricing
                            </a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#!">
                              Privacy Policy
                            </a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#!">
                              FAQ
                            </a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#!">
                              Contact
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="nk-footer-copyright fs-6 px-3">
                        &copy; {currentYear} All Rights Reserved to <a href="#!">HumGPT</a>.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserLayout;
