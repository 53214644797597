import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import PageLoader from "../../../Components/Loader/PageLoader";
import { Link } from "react-router-dom";
import { usePlan } from "../../../Context/PlanContext";

const CustomPricingPlans = () => {
    const [plans, setPlans] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const {isPlanActive, tokenFinished ,isReadWrite} = usePlan();
    const getPlans = () => {
        setPageLoading(true);
        axios.get(`${Helpers.apiUrl}plans/all`, Helpers.authHeaders).then(response => {
            setPlans(response.data.plans);
            setPageLoading(false);
        });
    }

    useEffect(() => {
        getPlans();
    }, []);

        // useEffect(() => {
        //     if (Helpers.authUser.plan_id && Helpers.authUser.latest_transaction) {
        //         const expiryDate = new Date(Helpers.authUser.latest_transaction.expiry_date);
        //         const currentDate = new Date();
        //         if (expiryDate < currentDate) {
        //             setIsPlanActive(false);
        //         } else {
        //             setIsPlanActive(true);
        //         }
        //     }
        // }, []); // Empty dependency array ensures this runs only once on mount

    return (
        <>
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    {pageLoading ? <PageLoader /> : <div className={`nk-content-body`}>
                        <div className={`nk-block-head nk-page-head`}>
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Pricing Plans</h2>
                                    <p>Generate unlimited copy 10X faster with our cost effective plan to write blog posts, social media ads and many more.</p>
                                    {(isPlanActive == "Active" && !tokenFinished) && (<h3 className="display-8">You're already subscribed to a plan</h3>)}
                                    {((isPlanActive == "Active" || isPlanActive == "Expired") && tokenFinished) && (<h3 className="display-8">Your Tokens are finished.</h3>)}
                                    {isPlanActive == "Expired" && (<h3 className="display-8 text-danger">Your Plan Is Expired</h3>)}
                                </div>
                            </div>
                        </div>
                        <div className={`nk-block ${(isPlanActive == "Active" && !tokenFinished) ? 'disabled-overlay' : ''}`}>
                            <div className="mt-xl-5">
                                <div className="row g-0">
                                    {plans.map((plan, index) => {
                                        return (
                                            <div key={index} className="col-xl-4 p-1">
                                                <div className="card pricing bg-white rounded-start h-100">
                                                    <div className="pricing-content">
                                                        <div className="w-sm-70 w-md-50 w-xl-100 text-center text-xl-start mx-auto">
                                                            <h2 className="mb-3">{plan.plan_name}</h2>
                                                            <div className="pricing-price-wrap">
                                                                <div className="pricing-price">
                                                                    <h3 className="display-3 mb-3 fw-semibold">${parseFloat(plan.monthly_price).toFixed(2)} <span className="caption-text text-light fw-normal"> / month</span></h3>
                                                                </div>
                                                            </div>
                                                            <div className="mb-2">
                                                                <Link to={`/user/subscribe-product/${plan.id}`} className="btn btn-outline-light w-100">Upgrade Now</Link>
                                                                <div className="d-flex align-items-center justify-content-center text-center text-light fs-12px lh-lg fst-italic mt-1">
                                                                    <svg width="13" height="13" viewBox="0 0 13 13" className="text-danger" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.5 2.375V10.625" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M2.9281 4.4375L10.0719 8.5625" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M2.9281 8.5625L10.0719 4.4375" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </svg>
                                                                    <span className="ms-1">Cancel Anytime</span>
                                                                </div>
                                                            </div>
                                                            <ul className="pricing-features">
                                                                {plan.plan_features.map(feature => {
                                                                    return <li key={feature.feature}><em className="icon text-primary ni ni-check-circle"></em><span>{feature.feature}</span></li>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="mt-5">
                                <h5>Want to learn more about our pricing &amp; plans?</h5>
                                <p>Read our <a href="#">Plans</a>, <a href="!#">Billing &amp; Payment</a>, <a href="!#">FAQs</a> to learn more about our service.</p>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        
        <style jsx="true">{`
            
    
            .card {
              transition: box-shadow 0.3s ease, transform 0.3s ease;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
    
            .card:hover {
              box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
              transform: translateY(-5px);
            }
          `}</style>
          </>
    );
}

export default CustomPricingPlans;
