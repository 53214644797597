import React, { useState, useEffect } from "react";
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditUser = () => {
  const navigate = useNavigate();
  const { user_id } = useParams(); // Access the user_id parameter using useParams

  const [userData, setUserData] = useState({
    id: null,
    name: "",
    email: "",
    isActive: false, // Replace plan_id with isActive
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false); // Validation state

  useEffect(() => {
    axios
      .get(`${Helpers.apiUrl}user/get/${user_id}`, Helpers.authHeaders)
      .then((response) => {
        if (response.data.user) {
          setUserData({
            ...response.data.user,
            isActive: response.data.user.is_active, // Assuming is_active is the field from the API
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [user_id]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateFields = () => {
    const { name, email } = userData;
    if (name !== "" && email !== "") {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    validateFields();
  }, [userData]); // Trigger validation whenever userData changes

  const updateUser = () => {
    if (!isValid) {
      setError("Please fill in all required fields.");
      return;
    }

    setIsLoading(true);
    axios
      .post(
        `${Helpers.apiUrl}user/update/${user_id}`,
        {
          name: userData.name,
          email: userData.email,
          is_active: userData.isActive,
        },
        Helpers.authHeaders
      )
      .then((response) => {
        setMessage(response.data.message);
        setIsLoading(false);
        Helpers.toast("success", response.data.message); // Show success toast
        navigate('/admin/users');
      })
      .catch((error) => {
        setError(error.response.data.message);
        setIsLoading(false);
        Helpers.toast("error", error.response.data.message); // Show error toast
      });
  };

  return (
    <div className="nk-content chatbot-mb">
      <div className="container-xl">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="d-flex flex-wrap justify-content-center">
              <div className="bg-white w-100 w-lg-50 d-flex align-items-center justify-content-center justify-content-lg-start p-4 p-sm-5 shadow-lg rounded">
                <div className="wide-xs w-100">
                  <h2 className="mb-3 fw-normal">Update User Information</h2>
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      Name
                    </label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={userData.name || ""}
                        onChange={handleInputChange}
                        placeholder="Full Name"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        value={userData.email || ""}
                        onChange={handleInputChange}
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="isActive">
                      Active
                    </label>
                    <div className="form-control-wrap">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="isActive"
                        checked={userData.isActive}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-2">
                    <button
                      onClick={updateUser}
                      disabled={isLoading || !isValid}
                      className="btn btn-primary w-100"
                    >
                      {isLoading ? "Updating..." : "Update User"}
                    </button>
                  </div>
                  {error && <p className="text-danger">{error}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
