// import axios from "axios";
// import { useEffect, useState, useRef } from "react";
// import Helpers from "../../../Config/Helpers";
// import PageLoader from "../../../Components/Loader/PageLoader";
// import { Link, useParams, } from "react-router-dom";
// import {
//     Clipboard,
//     Paperclip,
//     ThumbsDown,
//     ThumbsUp,
//     Send,
//     Download,
//     X,
// } from "react-feather";
// import { jsPDF } from "jspdf";
// import { Document, Packer, Paragraph } from "docx";
// import { saveAs } from "file-saver";
// import ChatGPTFormatter from "../../../Components/ChatgptFormatter";

// const MyTemplatesLibrary = () => {

//     const { chatid } = useParams();
//     const messagesEndRef = useRef(null);
//     const [chat, setChat] = useState({});
//     const [categories, setCategories] = useState([]);
//     const [selectedCategory, setSelectedCategory] = useState(0);
//     const [prompts, setPrompts] = useState([]);
//     const [pageLoading, setPageLoading] = useState(false);
//     const [filteredPrompts, setFilteredPrompts] = useState([]);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [isDone, setIsDone] = useState(true);
//     const [userInput, setUserInput] = useState("");
//     const [buttons, setButtons] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [messages, setMessages] = useState([]);

//     const scrollToBottom = () => {
//         window.scrollTo(0, document.body.scrollHeight);
//         // messagesEndRef.current?.scrollIntoView({ behavior: "smooth", top: messagesEndRef.current.scrollHeight + 200, block:"end" })
//     };

//     const getButtons = (prompt_id) => {
//         axios
//             .get(`${Helpers.apiUrl}button/prompt/${prompt_id}`, Helpers.authHeaders)
//             .then((response) => {
//                 setButtons(response.data.buttons);
//             });
//     };

//     const downloadSingleMessagePDF = (message) => {
//         const pdf = new jsPDF();
//         const margins = { top: 10, bottom: 10, left: 10, right: 10 };
//         const pageHeight = pdf.internal.pageSize.height;
//         const lineHeight = 10;
//         let cursorY = margins.top;

//         const splitText = pdf.splitTextToSize(
//             message.message,
//             pdf.internal.pageSize.width - margins.left - margins.right
//         );

//         splitText.forEach((line) => {
//             if (cursorY + lineHeight > pageHeight - margins.bottom) {
//                 pdf.addPage();
//                 cursorY = margins.top;
//             }
//             pdf.text(margins.left, cursorY, line);
//             cursorY += lineHeight;
//         });

//         pdf.save(`chat_message_${new Date().toISOString()}.pdf`);
//     };
//     const downloadSingleMessageExcel = (message) => {
//         const XLSX = require("xlsx");
//         const ws = XLSX.utils.json_to_sheet([{ message: message.message }]);
//         const wb = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(wb, ws, "Message");

//         XLSX.writeFile(wb, `chat_message_${new Date().toISOString()}.xlsx`);
//     };

//     const downloadSingleMessageWord = async (message) => {
//         const doc = new Document({
//             sections: [
//                 {
//                     properties: {},
//                     children: [new Paragraph(message.message)],
//                 },
//             ],
//         });

//         const blob = await Packer.toBlob(doc);
//         saveAs(blob, `chat_message_${new Date().toISOString()}.docx`);
//     };

//     const getChat = () => {
//         if (isDone) {
//             setIsDone(false);
//             setPageLoading(true);
//             axios
//                 .get(`${Helpers.apiUrl}chat/get/${chatid}`, Helpers.authHeaders)
//                 .then((response) => {
//                     setChat(response.data);
//                     if (response.data.prompt_question_id) {
//                         getButtons(response.data.prompt_question_id);
//                     }
//                     setPageLoading(false);
//                     if (
//                         response.data &&
//                         response.data.messages &&              //toq added line 109 and 110 for type error
//                         response.data.messages.length === 0 ||
//                         response.data.chat_message === ""
//                     ) {
//                         getFirstResponse();
//                     } else {
//                         setMessages(response.data.messages);
//                         setTimeout(() => {
//                             scrollToBottom();
//                         }, 500);
//                     }
//                 });
//         }
//     };

    

//     const getCategories = () => {
//         axios.get(`${Helpers.apiUrl}category/all`, Helpers.authHeaders).then(response => {
//             setCategories(response.data);
//         });
//     }

//     const getPrompts = () => {
//         setPageLoading(true);
//         axios.get(`${Helpers.apiUrl}prompt/my-templates-library`, Helpers.authHeaders).then(response => {
//             setPrompts(response.data);
//             setFilteredPrompts(response.data);
//             setPageLoading(false);
//         });
//     }

//     const filterPrompts = category_id => {
//         setSelectedCategory(category_id);
//         if (category_id === 0) {
//             setFilteredPrompts(prompts);
//         } else {
//             let filtered = prompts.filter(prompt => prompt.category_id === category_id);
//             setFilteredPrompts(filtered);
//         }
//     }

//     const getFirstResponse = () => {
//         setIsLoading(true);
//         let msg = {
//           message: "",
//           user_id: Helpers.authUser.id,
//           chat_id: chat.id,
//           is_bot: 1,
//           is_hidden: 0,
//           is_included: 1,
//         };
//         let msgs = messages;
//         msgs.push(msg);
//         setMessages(msgs);
//         setTimeout(() => {
//           scrollToBottom();
//         }, 500);
//         console.log('mesages',messages)
//         const data = {
//           chatid: chatid,
//         };
//         const controller = new AbortController();
//         const signal = controller.signal;
//         let response;
//         fetch(`${Helpers.apiUrl}bot/init-response`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//           body: JSON.stringify(data),
//           signal,
//         })
//           .then((res) => {
//             response = res;
//             if (!response.ok) {
//               response.json().then((error) => {
//                 Helpers.toast("error", error.message);
//                 setIsLoading(false);
//               });
//             } else {
//               const reader = response.body.getReader();
//               const decoder = new TextDecoder();
    
//               function processText({ done, value }) {
//                 if (done) {
//                   setIsLoading(false);
//                   return;
//                 }
//                 let text = decoder.decode(value);
//                 if (text.endsWith("[DONE]")) {
//                   text = text.slice(0, -6);
//                 }
//                 let withLines = text.replace(/\\n/g, "\n");
//                 setMessages((prevMessages) => {
//                   const updatedMessages = [...prevMessages];
//                   updatedMessages[messages.length - 1].message += withLines;
//                   return updatedMessages;
//                 });
//                 setTimeout(() => {
//                   scrollToBottom();
//                 }, 500);
//                 reader.read().then(processText);
//               }
//               reader.read().then(processText);
//             }
//           })
//           .catch((error) => {
//             console.log("ERROR::", error);
//             setIsLoading(false);
//           });
//       };

//     const getResponse = (btnPrompt = "") => {
//         if (btnPrompt || userInput) {
//             setIsLoading(true);
//             let msg = {
//                 message: btnPrompt ? btnPrompt : userInput,
//                 user_id: Helpers.authUser.id,
//                 chat_id: chat.id,
//                 is_bot: 0,
//                 is_hidden: 0,
//                 is_included: 1,
//             };
//             let msgs = messages;  // Array.isArray(messages) ? [...messages] : [];
//             msgs.push(msg);
//             setMessages(msgs);
//             setTimeout(() => {
//                 scrollToBottom();
//             }, 500);
//             const data = {
//                 chatid: chatid,
//                 input: btnPrompt ? btnPrompt : userInput,
//             };
//             addMessage();
//             setUserInput("");
//             const controller = new AbortController();
//             const signal = controller.signal;
//             fetch(`${Helpers.apiUrl}bot/response`, {
//                 method: "POST",

//                 headers: {
//                     "Content-Type": "application/json",
//                     Authorization: `Bearer ${localStorage.getItem("token")}`,
//                 },
//                 body: JSON.stringify(data),
//                 signal,
//             })
//                 .then((response) => {
//                     if (!response.ok) {
//                         response.json().then((error) => {
//                             Helpers.toast("error", error.message);
//                             setIsLoading(false);
//                         });
//                     } else {
//                         const reader = response.body.getReader();
//                         const decoder = new TextDecoder();

//                         function processText({ done, value }) {
//                             if (done) {
//                                 setIsLoading(false);
//                                 return;
//                             }
//                             let text = decoder.decode(value);
//                             if (text.endsWith("[DONE]")) {
//                                 text = text.slice(0, -6);
//                             }
//                             let withLines = text.replace(/\\n/g, "\n");
//                             setMessages((prevMessages) => {
//                                 const updatedMessages = [...prevMessages];
//                                 updatedMessages[messages.length - 1].message += withLines;
//                                 return updatedMessages;
//                             });
//                             setTimeout(() => {
//                                 scrollToBottom();
//                             }, 500);
//                             reader.read().then(processText);
//                         }
//                         reader.read().then(processText);
//                     }
//                 })
//                 .catch((error) => {
//                     console.log("ERROR::", error);
//                     setIsLoading(false);
//                 });
//         } else {
//             Helpers.toast("error", "Can't send without input");
//         }
//     };

//     const searchTemplates = e => {
//         let value = e.target.value;
//         setSearchQuery(value);
//         setSelectedCategory(0);
//         let filtered = prompts.filter(prompt => prompt.name.toLowerCase().includes(value.toLowerCase()));
//         setFilteredPrompts(filtered);
//     }

//     const addMessage = () => {
//         let msg = {
//           message: "",
//           user_id: Helpers.authUser.id,
//           chat_id: chat.id,
//           is_bot: 1,
//           is_hidden: 0,
//           is_included: 1,
//         };
//         let msgs = messages;
//         msgs.push(msg);
//         setMessages(msgs);
//       };
    
//       useEffect(() => {
//         getChat();
//       }, []);

//     useEffect(() => {
//         getPrompts();
//         getCategories();
//     }, []);

//     return (
//         <div class="nk-content">
//             <div class="container-xl">
//                 <div class="nk-content-inner">
//                     {pageLoading ? <PageLoader /> : <div class="nk-content-body">
//                         <div class="nk-block-head nk-page-head">
//                             <div class="nk-block-head-between">
//                                 <div class="nk-block-head-content">
//                                     <h2 class="display-6">Template Library</h2>
//                                     <p>Choose the template which you want to use</p>
//                                 </div>
//                                 <div class="nk-block-head-content">
//                                     <div class="d-flex gap gx-4">
//                                         <div class="">
//                                             <div class="form-control-wrap">
//                                                 <div class="form-control-icon start md text-light"><em class="icon ni ni-search"></em></div>
//                                                 <input type="text" value={searchQuery} onChange={searchTemplates} class="form-control form-control-md" placeholder="Search Template" />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="nk-block">
//                             <ul class="filter-button-group mb-4 pb-1">
//                                 <li><button class={`filter-button ${selectedCategory === 0 ? 'active' : ''}`} onClick={() => filterPrompts(0)} type="button" data-filter="*">All</button></li>
//                                 {categories.map(category => <li><button class={`filter-button ${selectedCategory === category.id ? 'active' : ''}`} onClick={() => filterPrompts(category.id)} type="button">{category.name}</button></li>)}
//                             </ul>
//                             <div class="row g-gs filter-container" data-animation="true">
//                                 {filteredPrompts.map(prompt => {
//                                     return (
//                                         <div class="col-sm-6 col-xxl-3 filter-item blog-content">
//                                             <Link to={`/user/prompt-questions/${Helpers.encryptString(prompt.id)}/${prompt.name.replaceAll(" ", "-")}`}>
//                                                 <div class="card card-full shadow-none">
//                                                     <div class="card-body">
//                                                         <div class="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3">{prompt.name.charAt(0)}</div>
//                                                         <h5 class="fs-4 fw-medium">{prompt.name}</h5>
//                                                         <p class="small text-light line-clamp-2">{prompt.description}</p>
//                                                     </div>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     );
//                                 })}
//                             </div>
//                         </div>
//                         {/* for 3 buttons download */}
//                         <div class="nk-content chatbot-mb">
//         <div class="container-xl">
//           <div class="nk-content-inner">
//             {pageLoading ? (
//               <PageLoader />
//             ) : (
//               <div class="nk-content-body">
//                 <div class="nk-block">
//                   {Array.isArray(messages) && messages.map((msg, index) => { //toq added Array.isArray(messages) &&
//                     if (msg.is_hidden === 0) {
//                       return (
//                         <div
//                           key={index}
//                           className={`container chat-box ${
//                             msg.is_bot === 0 ? "bg-white" : "bot-bubble"
//                           }`}
//                         >
//                           <div className="row">
//                             <div className="col-12">
//                               <div className="row align-center">
//                                 <div className="col-6">
//                                   <div className="chat-header">
//                                     {msg.is_bot === 0 && (
//                                       <div>
//                                         <img
//                                           className="chat-avatar"
//                                           src={Helpers.serverImage(
//                                             Helpers.authUser.profile_pic
//                                           )}
//                                           alt=""
//                                         />
//                                       </div>
//                                     )}
//                                     {msg.is_bot === 1 && (
//                                       <div class="media media-middle media-circle text-bg-primary">
//                                         <img src="/favicon-white.png" alt="" />
//                                       </div>
//                                     )}
//                                     <span className="chat-user">
//                                       <strong>
//                                         {msg.is_bot === 1 ? "HumGPT" : "You"}
//                                       </strong>
//                                     </span>
//                                   </div>
//                                 </div>
//                                 <div className="col-6 text-right">
//                                   <div className="chat-actions">
//                                     <Clipboard className="pointer" size={20} />
//                                     <ThumbsUp
//                                       className="pointer ml20"
//                                       size={20}
//                                     />
//                                     <ThumbsDown
//                                       className="pointer ml20"
//                                       size={20}
//                                     />
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className="chat-divider"></div>
//                             </div>
//                             <div className="col-12">
//                               <p className="message">
//                                 <ChatGPTFormatter
//                                   response={msg.message}
//                                   writing={
//                                     messages.length - 1 === index && isLoading
//                                   }
//                                 />
//                               </p>
//                               {msg.is_bot === 1 && (
//                                 <div className="text-center">
//                                   <button
//                                     className="btn btn-outline-primary btn-sm ml10"
//                                     onClick={() =>
//                                       downloadSingleMessagePDF(msg)
//                                     }
//                                   >
//                                     <Download size={14} />{" "}
//                                     <span className="ml5">Download PDF</span>
//                                   </button>
//                                   <button
//                                     className="btn btn-outline-primary btn-sm ml10"
//                                     onClick={() =>
//                                       downloadSingleMessageExcel(msg)
//                                     }
//                                   >
//                                     <Download size={14} />{" "}
//                                     <span className="ml5">Download Excel</span>
//                                   </button>
//                                   <button
//                                     className="btn btn-outline-primary btn-sm ml10"
//                                     onClick={() =>
//                                       downloadSingleMessageWord(msg)
//                                     }
//                                   >
//                                     <Download size={14} />{" "}
//                                     <span className="ml5">Download Word</span>
//                                     </button>
//                                 </div>
//                               )}
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     }
//                   })}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//         <div id="something" ref={messagesEndRef} />
//       </div>
//                         <div className="nk-footer chat-bottom">
//                             <div className="container-xl">
//                                 <div className="row">
//                                     <div className="col-12 p0">
//                                         <div className="form-group">
//                                             <div className="form-control-wrap">
//                                                 <textarea
//                                                     className="chatbot-input"
//                                                     value={userInput}
//                                                     onChange={(e) => setUserInput(e.target.value)}
//                                                     placeholder="Write your message..."
//                                                 ></textarea>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-12 p0">
//                                         <div className="row">
//                                             <div className="col-6">
//                                                 {buttons.map((btn, index) => (
//                                                     <button
//                                                         onClick={() => getResponse(btn.prompt)}
//                                                         disabled={isLoading}
//                                                         className={`btn btn-primary btn-sm ${index > 0 && "ml10"
//                                                             }`}
//                                                     >
//                                                         {btn.name}
//                                                     </button>
//                                                 ))}
//                                             </div>
//                                             <div className="col-6 text-right">
//                                                 <small>{userInput.length} / 2000</small>
//                                                 <button
//                                                     className="btn btn-primary btn-sm ml20"
//                                                     disabled={isLoading}
//                                                     onClick={() => getResponse("")}
//                                                 >
//                                                     <Send size={14} /> <span className="ml10">Send</span>
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>}
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default MyTemplatesLibrary;

import axios from "axios";
import { useEffect, useState, useRef } from "react";
import Helpers from "../../../Config/Helpers";
import PageLoader from "../../../Components/Loader/PageLoader";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
    Clipboard,
    ThumbsDown,
    ThumbsUp,
    Send,
    Download,
} from "react-feather";
import { jsPDF } from "jspdf";
import { Document, Packer, Paragraph } from "docx";
import { saveAs } from "file-saver";
import ChatGPTFormatter from "../../../Components/ChatgptFormatter";
import * as XLSX from "xlsx";

const MyTemplatesLibrary = () => {

    const { chatid } = useParams();
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const [chat, setChat] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [prompts, setPrompts] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [filteredPrompts, setFilteredPrompts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isDone, setIsDone] = useState(true);
    const [userInput, setUserInput] = useState("");
    const [buttons, setButtons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]); 

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    const getButtons = (prompt_id) => {
        axios
            .get(`${Helpers.apiUrl}button/prompt/${prompt_id}`, Helpers.authHeaders)
            .then((response) => {
                setButtons(response.data.buttons);
            });
    };

    const downloadSingleMessagePDF = (message) => {
        const pdf = new jsPDF();
        const margins = { top: 10, bottom: 10, left: 10, right: 10 };
        const pageHeight = pdf.internal.pageSize.height;
        const lineHeight = 10;
        let cursorY = margins.top;

        const splitText = pdf.splitTextToSize(
            message.message,
            pdf.internal.pageSize.width - margins.left - margins.right
        );

        splitText.forEach((line) => {
            if (cursorY + lineHeight > pageHeight - margins.bottom) {
                pdf.addPage();
                cursorY = margins.top;
            }
            pdf.text(margins.left, cursorY, line);
            cursorY += lineHeight;
        });

        pdf.save(`chat_message_${new Date().toISOString()}.pdf`);
    };

    const downloadSingleMessageExcel = (message) => {
        const ws = XLSX.utils.json_to_sheet([{ message: message.message }]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Message");

        XLSX.writeFile(wb, `chat_message_${new Date().toISOString()}.xlsx`);
    };

    const downloadSingleMessageWord = async (message) => {
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: [new Paragraph(message.message)],
                },
            ],
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, `chat_message_${new Date().toISOString()}.docx`);
    };

    const getCategories = () => {
        axios.get(`${Helpers.apiUrl}category/all`, Helpers.authHeaders).then(response => {
            setCategories(response.data);
        });
    };

    const getPrompts = () => {
        setPageLoading(true);
        axios.get(`${Helpers.apiUrl}prompt/my-templates-library`, Helpers.authHeaders).then(response => {
            setPrompts(response.data);
            setFilteredPrompts(response.data);
            setPageLoading(false);
        });
    };

    const filterPrompts = category_id => {
        setSelectedCategory(category_id);
        if (category_id === 0) {
            setFilteredPrompts(prompts);
        } else {
            let filtered = prompts.filter(prompt => prompt.category_id === category_id);
            setFilteredPrompts(filtered);
        }
    };

    const searchTemplates = e => {
        let value = e.target.value;
        setSearchQuery(value);
        setSelectedCategory(0);
        let filtered = prompts.filter(prompt => prompt.name.toLowerCase().includes(value.toLowerCase()));
        setFilteredPrompts(filtered);
    };

    
    const nextTitle = () => {
        setIsLoading(true);
        if(userInput){
            let data = { "title": userInput };

            axios.post(`${Helpers.apiUrl}answer/savetitles`, data, Helpers.authHeaders)
                .then(response => {
                    console.log('response', response);
                    navigate(`/user/chat/${response.data.chat_id}`);
                    setIsLoading(false);
                })
                .catch(error => {
                    if (error.response) {
                        Helpers.toast("error", error.response.data.message);
                    } else {
                        Helpers.toast("error", "Unexpected error occurred");
                    }
                    setIsLoading(false);
                });
        }else{
            Helpers.toast("error", "write something first");
        }
    };

    useEffect(() => {
        getPrompts();
        getCategories();
    }, []);

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    {pageLoading ? (
                        <PageLoader />
                    ) : (
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-page-head">
                                <div className="nk-block-head-between">
                                    <div className="nk-block-head-content">
                                        <h2 className="display-6">Template Library</h2>
                                        <p>Choose the template which you want to use</p>
                                    </div>
                                    <div className="nk-block-head-content">
                                        <div className="d-flex gap gx-4">
                                            <div className="">
                                                <div className="form-control-wrap">
                                                    <div className="form-control-icon start md text-light">
                                                        <em className="icon ni ni-search"></em>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        value={searchQuery}
                                                        onChange={searchTemplates}
                                                        className="form-control form-control-md"
                                                        placeholder="Search Template"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <ul className="filter-button-group mb-4 pb-1">
                                    <li>
                                        <button
                                            className={`filter-button ${selectedCategory === 0 ? "active" : ""}`}
                                            onClick={() => filterPrompts(0)}
                                            type="button"
                                            data-filter="*"
                                        >
                                            All
                                        </button>
                                    </li>
                                    {categories.map((category) => (
                                        <li key={category.id}>
                                            <button
                                                className={`filter-button ${selectedCategory === category.id ? "active" : ""}`}
                                                onClick={() => filterPrompts(category.id)}
                                                type="button"
                                            >
                                                {category.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                <div className="row g-gs filter-container" data-animation="true">
                                    {filteredPrompts.map((prompt) => (
                                        <div className="col-sm-6 col-xxl-3 filter-item blog-content" key={prompt.id}>
                                            <Link to={`/user/prompt-questions/${Helpers.encryptString(prompt.id)}/${prompt.name.replaceAll(" ", "-")}`}>
                                                <div className="card card-full shadow-none">
                                                    <div className="card-body">
                                                        <div className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3">
                                                            {prompt.name.charAt(0)}
                                                        </div>
                                                        <h5 className="fs-4 fw-medium">{prompt.name}</h5>
                                                        <p className="small text-light line-clamp-2">{prompt.description}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="nk-content chatbot-mb">
                                <div className="container-xl">
                                    <div className="nk-content-inner">
                                        {pageLoading ? (
                                            <PageLoader />
                                        ) : (
                                            <div className="nk-content-body">
                                                <div className="nk-block">
                                                    {Array.isArray(messages) &&
                                                        messages.map((msg, index) => {
                                                            if (msg.is_hidden === 0) {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className={`container chat-box ${msg.is_bot === 0 ? "bg-white" : "bot-bubble"}`}
                                                                    >
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="row align-center">
                                                                                    <div className="col-6">
                                                                                        <div className="chat-header">
                                                                                            {msg.is_bot === 0 && (
                                                                                                <div>
                                                                                                    <img
                                                                                                        className="chat-avatar"
                                                                                                        src={Helpers.serverImage(Helpers.authUser.profile_pic)}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                            {msg.is_bot === 1 && (
                                                                                                <div className="media media-middle media-circle text-bg-primary">
                                                                                                    <img src="/favicon-white.png" alt="" />
                                                                                                </div>
                                                                                            )}
                                                                                            <span className="chat-user">
                                                                                                <strong>
                                                                                                    {msg.is_bot === 1 ? "HumGPT" : "You"}
                                                                                                </strong>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6 text-right">
                                                                                        <div className="chat-actions">
                                                                                            <Clipboard className="pointer" size={20} />
                                                                                            <ThumbsUp className="pointer ml20" size={20} />
                                                                                            <ThumbsDown className="pointer ml20" size={20} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="chat-divider"></div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <p className="message">
                                                                                    <ChatGPTFormatter
                                                                                        response={msg.message}
                                                                                        writing={messages.length - 1 === index && isLoading}
                                                                                    />
                                                                                </p>
                                                                                {msg.is_bot === 1 && (
                                                                                    <div className="text-center">
                                                                                        <button
                                                                                            className="btn btn-outline-primary btn-sm ml10"
                                                                                            onClick={() => downloadSingleMessagePDF(msg)}
                                                                                        >
                                                                                            <Download size={14} /> <span className="ml5">Download PDF</span>
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn btn-outline-primary btn-sm ml10"
                                                                                            onClick={() => downloadSingleMessageExcel(msg)}
                                                                                        >
                                                                                            <Download size={14} /> <span className="ml5">Download Excel</span>
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn btn-outline-primary btn-sm ml10"
                                                                                            onClick={() => downloadSingleMessageWord(msg)}
                                                                                        >
                                                                                            <Download size={14} /> <span className="ml5">Download Word</span>
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div id="something" ref={messagesEndRef} />
                            </div>
                            <div className="nk-footer-template chat-bottom-template">
                                <div className="container-xl">
                                    <div className="row">
                                        <div className="col-12 p0">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <textarea
                                                        className="chatbot-input"
                                                        value={userInput}
                                                        onChange={(e) => setUserInput(e.target.value)}
                                                        placeholder="Write your message..."
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 p0">
                                            <div className="row">
                                                {/* toq changed col-6 to col-12 */}
                                                <div className="col-12 text-right">
                                                    <small>{userInput.length} / 2000</small>
                                                    <button
                                                        className="btn btn-primary btn-sm ml20"
                                                        disabled={isLoading}
                                                        onClick={() => nextTitle()}
                                                    >
                                                        <Send size={14} /> <span className="ml10">Send</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyTemplatesLibrary;
